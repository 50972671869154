import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
/**
 * @desc 设置footer是否显示
 * @param {*} visible
 */
const setFooterVisible = (visible) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: 'SET_BASE_INFO',
      data: {
        show_footer: visible
      }
    })
  }, [])
}

/**
 * @desc 设置底部固定share模块是否显示
 * @param {*} visible
 */
const setSideFixedVisible = (visible) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: 'SET_BASE_INFO',
      data: {
        show_side_fixed: visible
      }
    })
  }, [])
}

/**
 * @desc 设置页面名称 首页：indexpage, 买车：search, 卖车：sales, 服务保障：other-service, 合伙人：other-partner-application
 * @param {*} name
 */
const setPageName = (name) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: 'SET_BASE_INFO',
      data: {
        page_name: name
      }
    })
  }, [])
}

export default {
  setFooterVisible,
  setSideFixedVisible,
  setPageName
}
