import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import logoImg from '~/img/buy/logo.png'
import './styles.scss'
import { sessionstorage } from '$utils'
// 线索提交成功弹窗
const ModalSuccess = (props) => {
  const {
    onClose, title, msg, c2Param, codeType, emission, closeGa
  } = props
  /**
     * 用于配置pc端成功弹窗微信二维码图片和log事件名对应关系
     * 统计微信二维码引流
     */
  const twocodeMap = {
    favorite_common: {
      imgSrc: '//img1.rrcimg.com/api/qrcode/0e740f17040a9dc2c500b944ccc93da2.png',
      logName: 'reservation_servicenumber3_display'
    },
    detail_appoint: {
      imgSrc: '//misc.rrcimg.com/api/qrcode/b6408b9af2460a92edad69e8d6f31c3e.png',
      logName: 'reservation_servicenumber1_display'
    },
    intent_common: {
      imgSrc: '//img1.rrcimg.com/api/qrcode/12c7f4d710b81460db5da09044bec8c7.png',
      logName: 'reservation_servicenumber2_display'
    }
  }
  const [visible, setVisible] = useState(props.visible)
  const ino = Number(sessionstorage.get('ino'))
  // TODO:弹窗的埋点曝光处理
  useEffect(() => { global.$rbl.push(twocodeMap[codeType || 'intent_common'].logName) })
  return (
    <Modal
      bodyStyle={{ padding: '0px' }}
      visible={visible}
      width={c2Param ? 450 : 370}
      centered
      footer={null}
      onCancel={() => {
        global.$rbl.push(closeGa || 'car-detail_submitsuccesss_close')
        setVisible(false)
        onClose && onClose()
      }}
    >
      <div className="r-modal-success">
        <div className="msg">
          <h3 className="title">
            <i className="iconfont icon-hehuoren_v8_2" />
            {title || '提交成功'}
          </h3>
          {msg && <p className="txt">{msg || '人人车客服会尽快联系您'}</p>}
          {ino === 1 ? (
            <p className="ino">
              <a href="/p/10/renrenche_yidigouche" target="_blank">
                了解异地购车注意事项 &gt;
              </a>
            </p>
          ) : null}
          {
            emission?.msg ? (
              <>
                <p className="ino">
                  <a href={emission.link}>
                    {emission.msg}
                  </a>
                </p>
              </>
            ) : <></>
          }
        </div>
        {(c2Param && c2Param.paramDay && c2Param.basicInfo) ? (
          <div className="orderinfo">
            <h2><i className="iconfont icon-car" />预约车辆</h2>
            <div className="car clearfix">
              <div className="car-pic"><img src={c2Param?.basicInfo?.search_image_url} alt={c2Param?.basicInfo?.title || ''} /></div>
              <div className="car-intro">
                <h6 className="car-title">{c2Param?.basicInfo?.title}</h6>
                <p className="car-price">{Number(c2Param?.basicInfo?.price).toFixed(2)}万</p>
              </div>
            </div>
            <h2>
              <i className="iconfont icon-dingwei1" />
              预估地点
              <span><p className="location">{c2Param.address}</p></span>
            </h2>
            <h2>
              <i className="iconfont icon-weimingming" />
              预估时间
              <span>
                {c2Param.paramDay?.length > 0 ? c2Param.paramDay.map(item => (
                  <p key={item.label}>
                    <span className="day">{item.label}</span>
                    {item.times.map(time => <span key={time} className="time">{moment(`${item.day} ${time}`).format('HH:mm')}-{moment(`${item.day} ${time}`).add(2, 'hours').format('HH:mm')}</span>)}
                  </p>
                )) : <p>待定</p>}
              </span>
            </h2>
          </div>
        ) : <></> }

        <div className="weixin clearfix">
          <div className="erweima">
            <img
              src={twocodeMap[codeType || 'intent_common'].imgSrc}
              alt="二维码"
              // data-exposure={twocodeMap[codeType || 'intent_common'].logName}
            />
          </div>
          <div className="txt-info">
            <p className="info-title">
              关注
              <img src={logoImg} alt="人人车" />
              公众号
            </p>
            <p className="info-item">
              <i className="iconfont icon-checked-06c22005" />
              查看车辆实时动态
            </p>
            <p className="info-item">
              <i className="iconfont icon-checked-06c22005" />
              售车情况随时知晓
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ModalSuccess.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  codeType: PropTypes.string,
  msg: PropTypes.string,
  c2Param: PropTypes.object,
  emission: PropTypes.object,
  closeGa: PropTypes.string
}

ModalSuccess.defaultProps = {
  visible: false,
  onClose: null,
  title: '提交成功',
  msg: '',
  codeType: 'intent_common',
  c2Param: null,
  emission: null,
  closeGa: ''
}

function mountAnywhere(Comp, root) {
  const div = document.createElement('div');
  (root || document.body).appendChild(div)

  // 向组件注入 onClose 方法，以便组件能调用关闭
  const Clone = React.cloneElement(Comp, {
    onClose: () => {
      ReactDOM.unmountComponentAtNode(div)
      div.parentNode.removeChild(div)
    }
  })
  ReactDOM.render(Clone, div)

  return div
}

export class UseModalSuccess {
  constructor(params) {
    this.visible = false
    this.ref = null
    this.paramData = {
      title: params.title,
      codeType: params.codeType,
      msg: params.msg,
      ino: params.ino,
      c2Param: params?.c2Param,
      emission: params?.emission,
      closeGa: params?.closeGa
    }
  }

  open() {
    this.visible = true
    this.ref = mountAnywhere(
      <ModalSuccess visible={this.visible} {...this.paramData} />
    )
    return this.ref
  }

  close() {
    this.visible = false
  }
}
export default ModalSuccess
