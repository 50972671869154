import React, { useEffect, useState, memo } from "react"
import classnames from "classnames"
import {
 Button, Checkbox, Popover, Row, Col, Icon, Input 
} from "antd"
import PropTypes from "prop-types"
import { showMultMap } from "../../const"
import { $hooks } from "$utils"
import styles from "./index.scss"

const SearchFilter = memo(({ filterOptions, searchObj, setSearchObj }) => {
  // =========================================筛选器===============================================
  const [searchObjTemp, setSearchObjTemp] = useState(searchObj) // 临时检索对象，当多选筛选器点击确认按钮时,赋值给检索对象， key为返回字段中完整的key

  const onMultChange = (multkey, checkedValues) => {
    const tempObj = { start: 0 }
    tempObj[multkey] = checkedValues
    setSearchObj(tempObj)
  }
  const onMultChangeTemp = (multkey, checkedValues) => {
    const tempObj = {}
    tempObj[multkey] = (checkedValues || []).filter(e => !!e)
    setSearchObjTemp(tempObj)
  }

  // =========================================筛选器:品牌===============================================
  // 筛选器热门品牌
  const [searchBrandsOptions, setSearchBrandsOptions] = useState(
    filterOptions?.top_brand?.list || []
  )
  useEffect(() => {
    setSearchBrandsOptions(filterOptions?.top_brand?.list || [])
  }, [filterOptions?.top_brand])
  // 用户选择品牌，需要填充到热门品牌
  const [searchBrandsOptionsUSelected, setSearchBrandsOptionsUSelected] =
    useState({})

  const onSelectedBrand = (b) => {
    global.$rbl.push(`search_filter-brand_${b.name}`)
    setSearchObj({
      brand: b.name,
      car_series: "",
      wd: "",
      start: 0
    })
    setSearchSeriesOptions(b.series)
  }

  // 品牌更多
  const bransMoreDataObj = filterOptions?.brand?.list?.reduce((p, c) => {
    if (!p[c["first_char"]]) {
      p[c["first_char"]] = []
    }
    p[c["first_char"]].push(c)
    return p
  }, {})

  // 筛选器:品牌:更多:弹窗
  const getBrandsMoreItemJsx = letter => (
    <div className="col-item clearfix" key={letter}>
      <span className="col-item__letter" key={letter}>
        {letter}
      </span>
      <div className="col-item__brands">
        {bransMoreDataObj[letter].map(e => (
          <span
            className="col-item__brands-item"
            key={e.name}
            onClick={() => {
              if (
                filterOptions?.top_brand?.list
                  ?.slice(
                    0,
                    filterOptions?.top_brand?.list?.length < 20
                      ? filterOptions?.top_brand?.list?.length
                      : 20
                  )
                  .filter(item => item.name === e.name).length === 0
              ) {
                setSearchBrandsOptionsUSelected({ ...e })
              }
              onSelectedBrand(e)
              setMoreListVisible(false)
            }}
          >
            {e.name}
          </span>
        ))}
      </div>
    </div>
  )

  const [isHovering, hoverProps] = $hooks.useHover({
    mouseEnterDelayMS: 0,
    mouseLeaveDelayMS: 100
  })
  const [isMoreHovering, moreHoverProps] = $hooks.useHover({
    mouseEnterDelayMS: 0,
    mouseLeaveDelayMS: 100
  })
  const [isMoreListShow, setMoreListVisible] = useState(false)
  useEffect(() => {
    setMoreListVisible(isHovering || isMoreHovering)
  }, [isHovering, isMoreHovering])

  // =========================================筛选器:车系:start===============================================
  const [searchSeriesOptions, setSearchSeriesOptions] = useState(
    filterOptions?.top_series?.list || []
  )
  const onSelectedSeries = (s) => {
    if (s.car_series) {
      setSearchObj({
        car_series: s.car_series,
        brand: s.brand,
        wd: "",
        start: 0
      })
    } else {
      setSearchObj({ car_series: s.name, wd: "", start: 0 })
    }
  }

  // =========================================筛选器:价格:start================================================
  const [searchPriceOptions, setSearchPriceOptions] = useState(
    filterOptions?.price?.list?.filter(e => !!e.value) || []
  )
  // 筛选器 自定义价格
  const [priceCus0, setPriceCus0] = useState()
  const [priceCus1, setPriceCus1] = useState()
  useEffect(() => {
    if (!(searchObj.price && searchObj.price[0])) {
      setPriceCus0()
      setPriceCus1()
      return
    }
    // 当前价格
    const curPrice = searchObj.price[0].split("-").sort((a, b) => a - b)
    setPriceCus0(curPrice[0])
    setPriceCus1(curPrice[1])

    // 是否自定义价格
    const _isCusPrice =
      filterOptions?.price?.list?.filter(e => e.value === searchObj.price[0])
        ?.length <= 0
    // 检测价格选项，用户自定义价格填充
    if (_isCusPrice) {
      setSearchPriceOptions([
        ...filterOptions?.price?.list?.filter(e => !!e.value),
        {
          name: `${curPrice[0]}-${curPrice[1]}万`,
          value: `${curPrice[0]}-${curPrice[1]}`
        }
      ])
    } else {
      setSearchPriceOptions(
        filterOptions?.price?.list?.filter(e => !!e.value)
      )
    }
  }, [searchObj?.price])

  // =========================================筛选器:更多:start===============================================
  const [multFilterJsxVisibleItemx, setMultFilterJsxVisibleItem] = useState("")
  // 筛选器多选内容
  const getMultFilterJsx = (multkey) => {
    // 去除-不限-选项
    const multOptions =
      filterOptions[multkey]?.list?.filter(e => !!e.value) || []
    // 全-三列-展示
    const getShowSpan = () => {
      switch (multkey) {
        case "level":
          return 8
        default:
          return 8
      }
    }
    // 颜色-根据数据单独设置样式
    const getMultCheckStyle = (_multkey, _multItem) => {
      if (_multkey === "car_color") {
        return {
          backgroundColor: _multItem.color,
          borderColor: _multItem.border_color,
          border: `1px solid ${
            _multItem.border_color ? _multItem.border_color : "transparent"
          }`
        }
      }
      return {}
    }
    return (
      <div>
        {multkey !== "car_color" && (
          <Checkbox.Group
            onChange={(e) => {
              onMultChangeTemp(multkey, e)
            }}
            className={classnames(`search-filter-mult-content ${multkey}`)}
            value={searchObjTemp[multkey]}
            defaultValue={searchObj[multkey]}
          >
            <Row>
              {multOptions.map(
                multItem => !(Number(multItem?.hidden || 0) === 1) && (
                    <Col key={multItem.name} span={getShowSpan()}>
                      <Checkbox
                        key={multItem.name}
                        value={multItem.value}
                        onChange={() => {
                          global.$rbl.push(
                            `search_filter-${multkey}_${multItem.name}`
                          )
                        }}
                      >
                        {multItem.name}
                      </Checkbox>
                    </Col>
                  )
              )}
            </Row>
          </Checkbox.Group>
        )}
        {/* 颜色单独设置 */}
        {multkey === "car_color" && (
          <div
            className={classnames(
              `search-filter-mult-content ${multkey} clearfix`
            )}
          >
            <Row>
              {multOptions.map(multItem => (
                <Col
                  key={multItem.value}
                  span={getShowSpan()}
                  data-click={`search_filter-${multkey}_${multItem.value}`}
                >
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    id={`color-checkbox-input${multItem.value}`}
                    key={multItem.value}
                    value={multItem.value}
                    defaultChecked={
                      searchObj[multkey]?.indexOf(multItem.value) > -1
                    }
                  />
                  <label
                    htmlFor={`color-checkbox-input${multItem.value}`}
                    style={getMultCheckStyle(multkey, multItem)}
                  >
                    <i className="iconfont icon-checked-06c22005"></i>
                  </label>
                  <label
                    className="label-txt"
                    htmlFor={`color-checkbox-input${multItem.value}`}
                  >
                    {multItem.name}
                  </label>
                </Col>
              ))}
            </Row>
          </div>
        )}
        {/* 确认按钮，点击确认或取消隐藏tip展示 */}
        <div className="search-filter-mult-btn">
          <Button
            type="primary"
            className="btn"
            onClick={() => {
              global.$rbl.push(`search_filter-${multkey}_确定`)
              if (multkey === "car_color") {
                const checkedColors = Array.from(
                  document.querySelectorAll(
                    'input[id^="color-checkbox-input"]:checked'
                  )
                ).map(item => item.value)
                onMultChange(multkey, checkedColors)
              } else {
                onMultChange(multkey, searchObjTemp[multkey] || [])
              }
              setMultFilterJsxVisibleItem("")
            }}
          >
            确定
          </Button>
          <Button
            className="btn"
            onClick={() => {
              // 点击取消，恢复筛选器的选项
              onMultChangeTemp(multkey, searchObj[multkey] || "")
              setMultFilterJsxVisibleItem("")
            }}
          >
            取消
          </Button>
        </div>
      </div>
    )
  }
  // =========================================筛选器:更多:end===============================================

  /**
   * 同步更新searchObjTemp对象，维护临时选中状态
   */
  useEffect(() => {
    setSearchObjTemp(searchObj)
  }, [searchObj])

  /**
   * 品牌重置，车系恢复热门车系
   */
  useEffect(() => {
    // 品牌重置，初始化车系
    if (!searchObj.brand) {
      setSearchSeriesOptions(filterOptions?.top_series?.list || [])
      searchObj.car_series && setSearchObj({ car_series: "" })
      return
    }
    // 根据品牌，填充车系
    const hotBrand = filterOptions?.top_brand?.list?.filter(
      item => item.name === searchObj.brand
    )
    if (hotBrand && hotBrand.length < 1) {
      const userSelectedBrand = filterOptions?.brand?.list.filter(
        item => item.name === searchObj.brand
      )
      if (userSelectedBrand && userSelectedBrand[0]) {
        setSearchBrandsOptionsUSelected(userSelectedBrand[0])
        setSearchSeriesOptions(userSelectedBrand[0].series)
      }
    } else {
      hotBrand?.length && setSearchSeriesOptions(hotBrand[0].series)
    }
  }, [searchObj.brand, filterOptions])

  return (
    <div className={styles["r-filter-main"]}>
      <div className="line clearfix">
        <div className="label">品牌：</div>
        <ul className="items">
          <li
            className={classnames("item", {
              hasChecked: !searchObj["brand"]?.length
            })}
            onClick={() => {
              global.$rbl.push(`search_filter-brand_不限`)
              setSearchObj({ brand: "", start: 0 })
            }}
          >
            <span>不限</span>
          </li>
          {(searchBrandsOptions || []).map(b => (
            <li
              className={classnames("item", {
                hasChecked: searchObj["brand"] === b.name
              })}
              key={b.name}
              onClick={() => {
                // global.$rbl.push(`search_filter-brand_${b?.name}`)
                onSelectedBrand(b)
              }}
            >
              {b.name}
            </li>
          ))}
          {/* 用户选择非热门品牌 */}
          {searchBrandsOptionsUSelected?.name && (
            <span
              className={classnames("item", {
                hasChecked:
                  searchObj["brand"] === searchBrandsOptionsUSelected.name
              })}
              key={searchBrandsOptionsUSelected.name}
              onClick={() => {
                // global.$rbl.push(`search_filter-brand_${b?.name}`)
                onSelectedBrand(searchBrandsOptionsUSelected)
              }}
            >
              {searchBrandsOptionsUSelected.name}
            </span>
          )}
          <span className="brnads-more" {...hoverProps}>
            <span className="more-txt"> 更多</span>
            {isMoreListShow && <Icon type="caret-up" />}
            {!isMoreListShow && <Icon type="caret-down" />}
          </span>
          {isMoreListShow && (
            <div className="brnads-more-list clearfix" {...moreHoverProps}>
              <div className="brnads-more-list__col">
                {bransMoreDataObj &&
                  Object.keys(bransMoreDataObj)
                    .slice(0, 6)
                    .map(getBrandsMoreItemJsx)}
              </div>
              <div className="brnads-more-list__col">
                {bransMoreDataObj &&
                  Object.keys(bransMoreDataObj)
                    .slice(6, 12)
                    .map(getBrandsMoreItemJsx)}
              </div>
              <div className="brnads-more-list__col">
                {bransMoreDataObj &&
                  Object.keys(bransMoreDataObj)
                    .slice(12, Object.keys(bransMoreDataObj).length)
                    .map(getBrandsMoreItemJsx)}
              </div>
            </div>
          )}
        </ul>
      </div>
      <div className="line clearfix series">
        <div className="label"> 车系：</div>
        <ul className="items">
          <li
            className={classnames("item", {
              hasChecked: !searchObj["car_series"]?.length
            })}
            onClick={() => {
              global.$rbl.push("search_filter-series_不限")
              setSearchObj({ car_series: "", start: 0 })
            }}
          >
            不限
          </li>
          {searchSeriesOptions.map(s => (
            <li
              className={classnames("item", {
                hasChecked:
                  searchObj["car_series"] === (s.name || s.car_series)
              })}
              key={s.name || s.car_series}
              onClick={() => {
                global.$rbl.push(`search_filter-series_${s.name}`)
                onSelectedSeries(s)
              }}
            >
              {s.name || s.car_series}
            </li>
          ))}
        </ul>
      </div>
      <div className="line clearfix">
        <span className="label"> 价格：</span>
        <ul className="items">
          <li
            className={classnames("item", {
              hasChecked: !searchObj["price"]?.length
            })}
            onClick={() => {
              global.$rbl.push(`search_filter-price_不限`)
              setSearchObj({ price: "", start: 0 })
            }}
          >
            不限
          </li>
          {(searchPriceOptions || []).map(priceItem => (
            <li
              className={classnames("item", {
                hasChecked:
                  (!searchObj["price"] && priceItem.name === "不限") ||
                  searchObj["price"]?.indexOf(priceItem.value) > -1
              })}
              key={priceItem.name}
              onClick={() => {
                global.$rbl.push(`search_filter-price_${priceItem.value}万`)
                onMultChange("price", priceItem.value ? [priceItem.value] : [])
              }}
            >
              {priceItem.name}
            </li>
          ))}
          {/* 自定义价格 */}
          <li className="filter-price-cus">
            <Input
              className="filter-price-cus__item"
              suffix="万"
              value={priceCus0 || ""}
              onInput={(v) => {
                global.$rbl.push(`search_filter-price_inputboxleft`)
                setPriceCus0(v.target.value)
              }}
            />
            <span>- </span>
            <Input
              className="filter-price-cus__item"
              suffix="万"
              value={priceCus1 || ""}
              onInput={(v) => {
                global.$rbl.push(`search_filter-price_inputboxright`)
                setPriceCus1(v.target.value)
              }}
            />
            {priceCus0 && priceCus1 && (
              <Button
                type="primary"
                onClick={() => {
                  const tempPrice = [priceCus0, priceCus1].sort(
                    (a, b) => a - b
                  )
                  const tempPriceStr = tempPrice.join("-")
                  global.$rbl.push("search_filter-price_confirm")
                  global.$rbl.push(
                    `pc_search_filter_customizedprice_${tempPriceStr}wan`
                  )
                  setPriceCus1(tempPrice[1])
                  setPriceCus0(tempPrice[0])
                  onMultChange("price", [tempPriceStr])
                }}
              >
                确定
              </Button>
            )}
          </li>
        </ul>
      </div>
      <div className="line clearfix filter-more">
        <span className="label"> 更多：</span>
        {showMultMap.map((multItemJsx, index) => (
          <Popover
            visible={multFilterJsxVisibleItemx === multItemJsx.key}
            key={multItemJsx.key + index}
            overlayClassName="filter-more-item-overlay"
            className={classnames(`filter-more-item `, {
              hasChecked:
                searchObj[multItemJsx.key] &&
                searchObj[multItemJsx.key].length > 0
            })}
            placement="bottomLeft"
            content={getMultFilterJsx(multItemJsx.key)}
            onVisibleChange={(val) => {
              setMultFilterJsxVisibleItem(val ? multItemJsx.key : "")
            }}
          >
            <span>
              <span className="txt">{multItemJsx.name}</span>
              <span className="icon down">
                <Icon type="caret-down" />
              </span>
              <span className="icon up">
                <Icon type="caret-up" />
              </span>
            </span>
          </Popover>
        ))}
      </div>
    </div>
  )
})

export default SearchFilter

SearchFilter.propTypes = {
  filterOptions: PropTypes.object,
  defaultSearchObj: PropTypes.object,
  searchObj: PropTypes.object,
  setSearchObj: PropTypes.func
}

SearchFilter.defaultProps = {
  filterOptions: {},
  defaultSearchObj: {},
  searchObj: {},
  setSearchObj: () => {}
}
