import { get, post } from '$fetch'

/**
 * 获取车源数据、分期方案
 * @param {String} cid 车源id
 * @returns
 */
export const getCarData = params => get('/lurker/v1/finance/car_installment', params, { isFormData: false, isAutoMsg: false })

/**
 * 分期好车推荐
 * @param {String} cid 车源id
 * @returns
 */
export const getFenqiRecommend = params => get('/lurker/v1/finance/staging_recommend', params, { isFormData: false, isAutoMsg: false })

/**
 * @desc 分期购车提交
 * @param {*} amount:
 * @param {*} car_id:
 * @param {*} city: 城市 全国
 * @param {*} device: ‘PC’
 * @param {*} flag:
 * @param {*} fr:
 * @param {*} mobile_number/phone
 * @param {*} month:
 * @param {*} phone_source:’pc‘
 * @param {*} product: "贷款"
 * @param {*} rrc_id
 * @param {*} source: "分期贷款"
 * @param {*} sub_product: "贷款"
 * @param {*} submit_sources: "贷款-PC-分期页-首屏banner/提交申请"
 * @param {*} vcode: "1111"
 * @param {*} verify_code: ""
 * @returns
 */
export const installmentIntent = params => post('/lurker/v1/intent/c2/installment', params, { isFormData: false, isAutoMsg: false })
