import React, { useEffect } from "react"

export default () => {
  // 北斗
  const initBeidou = (projectId) => {
    try {
      const __dc = document.createElement("script")
      const __s = document.getElementsByTagName("script")[0]
      const __url = "j1.58cdn.com.cn/beidou-sdk/browser/bundle.min.js"
      __dc.type = "text/javascript"
      __dc.async = false
      __dc.src = `${
        (document.location.protocol === "https:" ? "https://" : "http://") +
        __url
      }?v=${+new Date()}`
      __dc.onload = function () {
        // eslint-disable-next-line no-undef
        Beidou.init({
          projectId // Beidou平台申请的ProjectId
        })
        // eslint-disable-next-line no-undef
        Beidou.addGlobalEventProcessor((event) => {
          if (event.request.url.indexOf("/buy/detail/") > -1) {
            event.projectId = 736
          }
          if (
            event.request.url.indexOf("/buy") > -1 &&
            event.request.url.indexOf("/buy/detail/") < 0
          ) {
            event.projectId = 976
          }
          if (event.request.url.indexOf("/sell") > -1) {
            event.projectId = 878
          }
          return event
        })
      }
      __s.parentNode.insertBefore(__dc, __s)
    } catch (error) {
      console.log("initbeidou-error:", error)
    }
  }
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // 58北斗
      initBeidou(737) // 详情页：736；pc：737
    }
  }, [])
  return <></>
}
