export default [{
  name: '关于我们',
  route: 'aboutus'
}, {
  name: '联系我们',
  route: 'contact'
}, {
  name: '加入我们',
  route: 'career'
}, {
  name: '用户服务协议',
  route: 'terms'
}, {
  name: '廉正举报',
  route: 'probity'
}, {
  name: '隐私政策',
  route: 'privacy'
}, {
  name: '法律声明',
  route: 'law'
}, {
  name: '营业执照',
  route: 'license'
},
{
  name: '权限明细',
  route: 'app-auth-detail'
},
{
  name: '共享个人信息',
  route: 'app-third-share'
},
{
  name: '知识产权',
  route: 'intellectual'
},
{
  name: '商品信息发布规则',
  route: 'release-rules'
}]
