import {
  useState, useEffect, useRef, useCallback
} from 'react'
// import rbl from '@/assets/lib/ga/core'

/**
 * @desc  hover
 * @params [mouseEnterDelayMS, mouseLeaveDelayMS]
 * @return ['onMouseEnter', onMouseLeave]
 */
const useHover = ({ mouseEnterDelayMS = 0, mouseLeaveDelayMS = 0 }) => {
  const [isHovering, setIsHovering] = useState(false)
  let mouseEnterTimer = null
  let mouseOutTimer = null
  return [
    isHovering,
    {
      onMouseEnter: () => {
        clearTimeout(mouseOutTimer)
        mouseEnterTimer = setTimeout(
          () => setIsHovering(true),
          mouseEnterDelayMS
        )
      },
      onMouseLeave: () => {
        clearTimeout(mouseEnterTimer)
        mouseOutTimer = setTimeout(
          () => setIsHovering(false),
          mouseLeaveDelayMS
        )
      }
    }
  ]
}

/**
 * @desc EventListener
 */
function useEventListener(eventName, handler, element = process.browser && window) {
  const savedHandler = useRef()
  useEffect(() => { savedHandler.current = handler }, [handler])
  useEffect(() => {
    const eventListener = event => savedHandler.current(event)
    if (element && element.addEventListener) {
      element.addEventListener(eventName, eventListener)
      return () => { element.removeEventListener(eventName, eventListener) }
    }
    return () => ({})
  }, [eventName, element])
}

/**
 *
 * @param {} callback 回调函数
 * @param {} delay 时间
 * @returns [interval实例, 执行函数]
 */
const useInterval = (callback, delay) => {
  const savedCallback = useRef()
  const id = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => () => { clearInterval(id.current) }, [delay])

  const start = () => {
    id.current = setInterval(() => {
      savedCallback.current()
    }, delay)
  }

  return [id.current, start]
}

/* const useMonitor = (fn, buryP = rbl) => {
  if (!buryP) return fn
  const that = this

  return async (...value) => {
    await buryP.push(...value)
    return fn.apply(that, value)
  }
} */
/*
 * @Description: 同步hooks
 */
const useSyncCallback = (callback) => {
  const [proxyState, setProxyState] = useState({ current: false })

  const Func = useCallback(() => {
    setProxyState({ current: true })
  }, [proxyState])

  useEffect(() => {
    if (proxyState.current === true) { setProxyState({ current: false }) }
  }, [proxyState])

  useEffect(() => {
    proxyState.current && callback()
  })

  return Func
}

// 函数式组件每次渲染，函数都会被重建，导致平时用的 debounce 函数中的timer会重新创建，进而导致防抖失效
// 使用useRef来缓存timer变量
export const useDebounce = (fn, delay) => {
  const refTimer = useRef()
  return  (...args) => {
    if (refTimer.current) {
      clearTimeout(refTimer.current)
    }
    refTimer.current = setTimeout(() => {
      fn(args)
    }, delay)
  }
}

export default {
  useHover,
  useEventListener,
  useInterval,
  useSyncCallback,
  useDebounce
}
