import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

const RVideo = ({ config, autoplay }) => {
  const videoBox = useRef()
  const videoRef = useRef()
  const [paused, setPaused] = useState(!autoplay)

  const videoBoxClick = (e) => {
    e.preventDefault()
    setPaused(!videoRef.current.paused)
    if (videoRef.current.paused) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }

  return (
    <>
      <div ref={videoBox} onClick={(e) => { videoBoxClick(e) }} style={{ width: `${config?.width ? `${config.width}px` : '100%'}`, height: `${config?.height ? `${config.height}px` : '100%'}` }} className="r-video clearfix">
        <video
          ref={videoRef}
          width={config.width || '100%'}
          height={config.height || '100%'}
          poster={config.poster}
          controls
          className="r-video-v"
          preload="none"
          autoPlay={autoplay}
        >
          <source src={config.src} type="video/mp4" />
          <track default kind="captions" srcLang="en" />
          {config.track || '要查看此视频请启用JavaScript测试'}
        </video>
        {paused && <a className="r-video-p" style={{ width: `${config.width ? `${config.width}px` : '100%'}`, height: `${config.height ? `${config.height}px` : '100%'}` }}> <span> <i /></span></a>}
      </div>
    </>
  )
}

export default RVideo

RVideo.propTypes = {
  config: PropTypes.object,
  autoplay: PropTypes.bool
}

RVideo.defaultProps = {
  config: {},
  autoplay: false
}
