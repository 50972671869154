/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './index.scss'
import { Button, Input, Modal } from 'antd'
import { $cookie } from '$utils'
import CreditForm from '../credit-form'

const CreditApply = (props) => {
  const [phone, setPhone] = useState($cookie.get('rrc_login_phone') || '')
  const [formVisible, setFormVisible] = useState(false)
  return (
    <>
      <div className={classnames('r-credit-apply', { 'apply-fixed': props.fixed })}>
        {/* <p className="err">{errmsg}</p> */}
        <Input
          className="phone"
          type="tel"
          value={phone}
          maxLength={11}
          onChange={(e) => { setPhone(e.target.value) }}
          placeholder="手机号:"
        />
        <Button
          type="primary"
          className="btn"
          onClick={() => {
            global.$rbl.push({
              event: 'SubmitClue_Loan_Submit_click',
              content: {
                expand: {
                  submit_sources: props.submitSource
                }
              }
            })
            setFormVisible(true)
          }}
        >咨询金融顾问
        </Button>
      </div>
      <Modal bodyStyle={{ padding: 0 }} destroyOnClose visible={formVisible} closable centered footer={null} width={383} onCancel={() => { setFormVisible(false) }}>
        <CreditForm
          title="分期定制方案"
          btnTxt="提交"
          phone={phone}
          carId={props.carId}
          rrcId={props.rrcId}
          submitSources={props.submitSource}
          onSuccess={() => { setFormVisible(false) }}
        />
      </Modal>
    </>
  )
}
export default CreditApply

CreditApply.propTypes = {
  fixed: PropTypes.bool,
  submitSource: PropTypes.string,
  carId: PropTypes.string,
  rrcId: PropTypes.string
}

CreditApply.defaultProps = {
  fixed: false,
  submitSource: '',
  carId: '',
  rrcId: ''
}
