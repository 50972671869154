export default {
  getAll(ck = process.browser && document.cookie) {
    if (!ck) {
      return {}
    }
    const pairs = ck.split(";")
    const cookies = {}
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split("=")
      cookies[`${pair[0]}`.trim()] = decodeURI(pair.slice(1).join("="))
    }
    return cookies
  },
  /**
   * 获得 cookie
   * @param {String} name key
   */
  get(name, ck = process.browser && document.cookie) {
    const value = (name = RegExp(`(^| )${name}=([^;]*)(;|$)`).exec(ck))
    return value ? value[2] : ""
  },

  /**
   * 添加 cookie
   * @param {String} name key
   * @param {String} value value
   * @param {String} option.time 秒数
   * @param {String} option.domain 域
   * @param {String} option.path 路径
   * @param {Boolean} option.secure secure
   */
  set(name, value, option = {}) {
    let _time
    const { time, secure } = option
    if (time) {
      _time = new Date()
      _time.setTime(_time.getTime() + time * 1e3)
    }
    const params = []
    value = typeof value === "object" ? JSON.stringify(value) : value
    params.push(`${name}=${value}`)
    option.domain && params.push(`domain=${option.domain}`)
    params.push("path=/")
    _time && params.push(`expires=${_time.toGMTString()}`)
    secure && params.push("secure")
    document.cookie = params.join("; ")
  },

  /**
   * 删除 cookie
   * @param {String} name key
   */
  remove(name, secure, path = "/") {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=${path};secure=${secure};`
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;dimain=.renrenche.com;path=${path};secure=${secure};`
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;dimain=.www.renrenche.com;path=${path};secure=${secure};`
  }
}
