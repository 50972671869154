import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Cascader } from "antd"
import { useSelector } from "react-redux"
import { $hooks, sessionstorage } from "$utils"
import "./index.scss"
import { getAllBrands } from "$service"

const BrandSeriesSelect = (props) => {
  const [brandSeries, setBrandSeries] = useState({}) // 品牌车系对象
  const [brandSeriesOptions, setBrandSeriesOptions] = useState([]) // 品牌车系选择项
  const [defaultValue, setDefaultValue] = useState() // 品牌车系的默认值
  const [brandInputAllowClear, setBrandInputAllowClear] = useState(true)

  const search = useSelector(store => store.search)

  useEffect(() => {
    if (!(search?.searchBrand || search?.searchSeries)) {
      setDefaultValue([])
      return
    }
    const defaultVal = ["Hot"]
    if (search.searchBrand) {
      defaultVal.push(search.searchBrand)
    }
    if (search.searchSeries) {
      defaultVal.push(search.searchSeries)
    }
    setDefaultValue(defaultVal)
    setBrandSeries({
      brand_name: search.searchBrand,
      series_name: search.searchSeries
    })
  }, [search])

  // 品牌车系选择项（hover 时请求数据）
  const getAllBrandsFunc = async () => {
    try {
      // 优先从sessionStorage中获取数据
      let hot_brand = []
      let brand = []
      if (sessionstorage.get("hot_brand")) {
        hot_brand = sessionstorage.get("hot_brand")
        brand = sessionstorage.get("brand")
      } else {
        const result = await getAllBrands()
        // eslint-disable-next-line prefer-destructuring
        hot_brand = result.data.hot_brand
        // eslint-disable-next-line prefer-destructuring
        brand = result.data.brand
        sessionstorage.set("hot_brand", hot_brand)
        sessionstorage.set("brand", brand)
      }

      const hotBrandSeriesOptions = hot_brand.reduce(
        (p, c) => {
          p.children.push({
            value: c.brand_name,
            label: c.brand_name,
            children: c.series.reduce((p1, c1) => {
              p1.push({
                value: c1.series_name,
                label: c1.series_name
              })
              return p1
            }, [])
          })
          return p
        },
        {
          value: "Hot",
          label: "Hot",
          children: []
        }
      )
      const brandSeriesOptionsObj = brand.reduce((p, c) => {
        const fc = c.first_char.toUpperCase()
        if (!p[fc]) {
          p[fc] = {
            value: fc,
            label: fc,
            children: []
          }
        }
        p[fc].children.push({
          value: c.brand_name,
          label: c.brand_name,
          children: c.series.reduce((p1, c1) => {
            p1.push({
              value: c1.series_name,
              label: c1.series_name
            })
            return p1
          }, [])
        })
        return p
      }, {})
      const options = Object.values(brandSeriesOptionsObj)
      options.unshift(hotBrandSeriesOptions)
      setBrandSeriesOptions(options)
    } catch (error) {
      console.log(error)
    }
  }

  const [isHovering, hoverProps] = $hooks.useHover({
    mouseEnterDelayMS: 100,
    mouseLeaveDelayMS: 100
  })
  useEffect(() => {
    if (isHovering) {
      getAllBrandsFunc()
    }
  }, [isHovering])

  useEffect(() => {
    getAllBrandsFunc()
  }, [])

  useEffect(() => {
    props.onSelected && props.onSelected(brandSeries)
  }, [brandSeries])
  return (
    <Cascader
      {...hoverProps}
      className={`brand-series-select__value ${props.wrapperClass}`}
      allowClear={brandInputAllowClear}
      placeholder={props.placeholder}
      popupClassName="brand-series-select"
      showSearch
      size="large"
      options={brandSeriesOptions}
      onFocus={() => {
        if (!brandSeries.series_name) {
          setDefaultValue(["Hot"])
        }
      }}
      onBlur={() => {
        if (!brandSeries.series_name) {
          setDefaultValue()
        }
      }}
      onChange={(v) => {
        if (v.length) {
          setDefaultValue(v)
          setBrandSeries({
            brand_name: v[1],
            series_name: v[2]
          })
        } else {
          setDefaultValue([])
          setBrandSeries({})
        }
      }}
      displayRender={(labels) => {
        labels.shift()
        return labels.join("-")
      }}
      value={defaultValue}
      onPopupVisibleChange={(popVisble) => {
        setBrandInputAllowClear(!popVisble)
      }}
    />
  )
}

export default BrandSeriesSelect

BrandSeriesSelect.propTypes = {
  onSelected: PropTypes.func,
  wrapperClass: PropTypes.string,
  placeholder: PropTypes.string
}

BrandSeriesSelect.defaultProps = {
  onSelected: () => ({}),
  wrapperClass: "",
  placeholder: "请选择"
}
