import $cookie from "./cookie"
import localstorage from "./localstorage"
import sessionstorage from "./sessionstorage"
import $hooks from "./hooks"
import $config from "./config"

export {
 localstorage, sessionstorage, $cookie, $hooks, $config 
}

/**
 * @desc 返回埋点日志 JSON字符串
 * @param {*}
 */
export const ga = obj => JSON.stringify(obj)

/**
 * 是否是 json 字符串
 * @param {*} str
 */
export const isJSON = (str) => {
  if (typeof str === "string") {
    try {
      const obj = JSON.parse(str)
      if (typeof obj === "object" && obj) {
        return true
      }
      return false
    } catch (e) {
      // console.log('error：' + str + '!!!' + e)
      return false
    }
  }
  return false
}

// 是否是对象
export const isObj = (obj) => {
  const type = typeof obj
  return obj !== null && (type === "object" || type === "function")
}

// 驼峰转下划线 aBC => a_b_c
const snakeizeRE = /([A-Z])/g
export const snakeize = str => str.replace(snakeizeRE, (_, c) => `_${c.toLowerCase()}`)

// 把驼峰数据转换成下划线
export const snakeizeData = (data) => {
  const copy = Object.create(null)

  Object.keys(data).forEach((key) => {
    const cur = data[key]
    copy[snakeize(key)] = isObj(cur) ? snakeizeData(cur) : cur
  })

  return copy
}

export const isIOS = () => /ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase())

/**
 * @desc 判断 app 版本 v1 是否 大于 v2
 * @param {String} v1 5.9.0
 * @param {String} v2 4.11.11
 * @returns {Boolean}
 */
export const diffVersion = (v1, v2) => {
  v1 = v1.split(".")
  v2 = v2.split(".")
  const len = Math.max(v1.length, v2.length)

  while (v1.length < len) {
    v1.push("0")
  }
  while (v2.length < len) {
    v2.push("0")
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1[i], 10)
    const num2 = parseInt(v2[i], 10)

    if (num1 > num2) {
      return 1
    }
    if (num1 < num2) {
      return -1
    }
  }

  return 0
}

/*
 * @desc base64 加密
 */
/* eslint-disable */
export const base64Encrypt = (e) => {
  var t,
    n,
    o,
    r,
    a,
    i,
    u = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  for (o = e.length, n = 0, t = ""; o > n; ) {
    if (((r = 255 & e.charCodeAt(n++)), n == o)) {
      (t += u.charAt(r >> 2)), (t += u.charAt((3 & r) << 4)), (t += "==");
      break;
    }
    if (((a = e.charCodeAt(n++)), n == o)) {
      (t += u.charAt(r >> 2)),
        (t += u.charAt(((3 & r) << 4) | ((240 & a) >> 4))),
        (t += u.charAt((15 & a) << 2)),
        (t += "=");
      break;
    }
    (i = e.charCodeAt(n++)),
      (t += u.charAt(r >> 2)),
      (t += u.charAt(((3 & r) << 4) | ((240 & a) >> 4))),
      (t += u.charAt(((15 & a) << 2) | ((192 & i) >> 6))),
      (t += u.charAt(63 & i));
  }
  return t;
};
/* eslint-enable */

/**
 * 判断手机号是否正确
 * @param {*} phone
 * @return Boolean
 */
export const isPhone = (phone = "") => /^1[3456789]\d{9}$/.test(phone)

/**
 * 获取年份和月
 * @param {String} 日期
 * @return String
 */
export const getYearMonth = (date) => {
  const _date = date ? new Date(date) : new Date()
  const year = _date.getFullYear()
  const month = _date.getMonth() + 1
  return `${year}年${month < 10 ? `0${month}` : month}月`
}

/**
 * @desc 获取url search参数
 * @param {String} name
 * @returns {String}
 */
export const getUrlSearch = (name) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, "i")
  const r = window.location.search.substr(1).match(reg)
  if (r !== null) {
    return decodeURIComponent(r[2])
  }
  return null
}

/**
 * 判断验证码是否正确
 * @param {*} phone
 * @return Boolean
 */
export const isCode = (code = "") => code.length === 4

/**
 * 服务端 cookie转json
 * @params haders.cookie
 * @return {key:value}
 */
export const cookieToJson = (cookie) => {
  const arr = cookie.split("; ")
  const obj = {}
  arr.forEach((i) => {
    const kv = i.split("=")
    // eslint-disable-next-line prefer-destructuring
    obj[kv[0]] = kv[1]
  })
  return obj
}

// 判断砍价价格是否正确 最多两位小数的数字：/^d+(.d{1,2})?$/
export const isCutPrice = (price = 0) => /^\d+(.\d{1,2})?$/g.test(`${price}`)
// 只能输入数字
export const isNumberInput = (price = 0) => !Number.isNaN(Number(price))

// 防抖
export const debounce = (fn, delay) => {
  let timer = null
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}

// 节流
export const throttle = (fn, delay) => {
  let timer = null
  return (...args) => {
    if (!timer) {
      timer = setTimeout(() => {
        fn.apply(this, args)
        timer = null
      }, delay)
    }
  }
}

// 图片预加载
export const preloadImg = (imgArr) => {
  if (imgArr instanceof String) {
    const img = new Image()
    img.src = imgArr
    return null
  }
  if (imgArr instanceof Array) {
    const imagesArr = []
    imgArr.map((src, index) => {
      imagesArr[index] = new Image()
      imagesArr[index].src = src
      return null
    })
  }
  return null
}

/**
 * @desc 设置ie10以上 Boolean值
 * @returns true IE10 及以上  false 否
 */
export const $gtIE = (ieinfo = {}) => ieinfo?.version && ieinfo.version >= 10 && ieinfo.time <= Date.now()

/**
 * @desc 设置ie9以下 Boolean值
 * @returns true IE9 及以下  false 否
 */
export const $ltIE = (ieinfo = {}) => ieinfo?.version && ieinfo.version <= 9 && ieinfo.time <= Date.now()

/** */
export const checkCommonApiSuccess = async (options = {}) => {
  const { store, getPublicVariables } = options
  try {
    if (process.browser && !$cookie.get("server-common-request")) {
      const _cookie = document.cookie
      const _fr = $cookie.get("rrc_fr", _cookie)
      const result = await getPublicVariables({ fr: _fr || "" })
      // 保存city
      store.dispatch({
        type: "SET_CITY_INFO",
        data: result.data.data.city
      })
      // 注入cookie
      for (const cookieName in result.data.data.cookie) {
        const v = result.data.data.cookie[cookieName]
        $cookie.set(cookieName, v)
      }
      // 移除服务端注入的标记cookie
      $cookie.remove("server-common-request")
    }
  } catch (error) {
    // console.log(error)
  }
}

/**
 * @desc 用户唯一标识ID
 * @return {String}
 */
export const logUserid = () => $cookie.get("rrc_userid") || ""
export const nvuid = () => $cookie.get("new_visitor_uuid")

export const getNewVisitorUUID = () => {
  const UUID = (function (uuidRegEx, uuidReplacer) {
    return function () {
      return "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
        .replace(uuidRegEx, uuidReplacer)
        .toLowerCase()
    }
  })(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0; // eslint-disable-line
    let v = c === "x" ? r : (r & 3) | 8; // eslint-disable-line
    return v.toString(16)
  })
  return UUID()
}

export const getRslUUID = () => {
  const UUID = (function (uuidRegEx, uuidReplacer) {
    return function () {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
        .replace(uuidRegEx, uuidReplacer)
        .toLowerCase()
    }
  })(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0; // eslint-disable-line
    let v = c === "x" ? r : (r & 3) | 8; // eslint-disable-line
    return v.toString(16)
  })
  return UUID()
}

// 浮点数运算
export const getPointPow = (num1, num2) => {
  // console.log(num1, num2)
  // if (typeof num2 !== 'number') {
  //   throw new TypeError('数字不合法')
  // }
  let pointLength1 = 0
  let pointLength2 = 0
  // 如果存在小数点
  if (`${num1}`.indexOf(".") > -1) {
    pointLength1 = `${num1}`.split(".")[1].length
  }
  if (`${num2}`.indexOf(".") > -1) {
    pointLength2 = `${num2}`.split(".")[1].length
  }
  // eslint-disable-next-line no-restricted-properties
  return Math.pow(10, Math.max(pointLength1, pointLength2))
}

export const floatAdd = (num1, num2) => {
  const pow = getPointPow(num1, num2)
  return (num1 * pow + num2 * pow) / pow
}

export const floatSubtract = (num1, num2) => {
  const pow = getPointPow(num1, num2)
  return (num1 * pow - num2 * pow) / pow
}

export const floatMultiple = (num1, num2) => {
  const pow = getPointPow(num1, num2)
  return (num1 * pow * (num2 * pow)) / (pow * pow)
}

export const floatDivide = (num1, num2) => {
  const pow = getPointPow(num1, num2)
  return (num1 * pow) / (num2 * pow)
}
/**
 * 获取提交线索成功弹窗的提示文案
 * @param {*} intentType
 * @param {*} now
 * @returns
 */
export const getSuccessMsg = (intentType, now = new Date()) => {
  switch (intentType) {
    case "order":
      return "我们会尽快联系您，确认看车事宜"
    case "consult":
      return "人人车客服将尽快联系您，为您解答车况问题"
    case "discount_notify":
      return "车主降价后，我们会尽快联系您"
    case "bangmai":
      return "帮买顾问已开始为您找车，请保持手机畅通"
    case "bargain":
      if (now.getHours() < 8 || now.getHours() > 21) {
        return "人人车小伙伴下班了，上班后会尽快联系您，请注意接听来电"
      }
      return "人人车客服将尽快联系您，请保持手机畅通"
    default:
      return "人人车客服会尽快联系您"
  }
}

export const getPagename = () => {
  const { pathname } = window.location
  if (/\/installment\/detail/.test(pathname)) {
    return "分期页"
  }
  if (/^\/installment\/apply\//.test(pathname)) {
    return "分期详情页"
  }
  if (/^\/buy\/detail/.test(pathname)) {
    return "详情页"
  }
  if (/^\/buy/.test(pathname)) {
    return "列表页"
  }
  if (/^\/login/.test(pathname)) {
    return "登录页"
  }
  if (/^\/sell\/addinfo/.test(pathname)) {
    return "卖车页-信息补充"
  }
  if (/^\/sell/.test(pathname)) {
    return "卖车页"
  }
  return window.location.pathname
}

// 第一次线索提交埋点上报
export const intentFirstSubmitTimeGa = (intent, submitSources) => {
  const pageLoadTime = sessionstorage.get("pageLoadTime")
  if (pageLoadTime && pageLoadTime !== "null") {
    sessionstorage.set("pageLoadTime", null)
    global.$rbl.push({
      event: "intent_first_submit_time",
      content: {
        time: new Date().getTime() - pageLoadTime,
        intent: intent,
        platform: "PC",
        submit_sources: submitSources || ""
      }
    })
  }
}

export function jsonp(url) {
  return new Promise((resolve) => {
    // 这里的 "jsonCallBack" ，和调用的 jsonp 的 url 中的 callback 值相对应
    window.jsonCallBack = (result) => {
      resolve(result)
    }

    const JSONP = document.createElement("script")

    JSONP.type = "text/javascript"

    JSONP.src = `${url}${url.includes("?") ? "&" : "?"}callback=jsonCallBack`

    document.getElementsByTagName("head")[0].appendChild(JSONP)
    setTimeout(() => {
      document.getElementsByTagName("head")[0].removeChild(JSONP)
    }, 500)
  })
}

export function isLogin() {
  return $cookie.get("rrc_login_phone") && $cookie.get("rrc_login_token")
}
