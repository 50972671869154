import axios from 'axios'
import { Component } from 'react'
import { message } from 'antd'
import { addPendingRequest, removePendingRequest } from './http.abort'


message.config({
  top: 70,
  maxCount: 1
})
// 判断是否是客户端
const isCSR = process.browser

// 当前正在请求的数量
let requestCount = 0
// 显示loading
function showLoading() {
  const isLoading = !!document.getElementById('rc-loading')
  if (requestCount === 0 && !isLoading) {
    // const dom = document.createElement('div')
    // dom.setAttribute('id', 'rc-loading')
    // document.body.appendChild(dom)
    // ReactDOM.render(<div />, dom)
  }
  requestCount++
}

// 隐藏loading
function hideLoading() {
  requestCount--
  if (requestCount === 0) {
    if (document.getElementById('rc-loading')) {
      document.body.removeChild(document.getElementById('rc-loading'))
    }
  }
}

const service = axios.create({
  timeout: 30000, // 请求超时时间
  withCredentials: true, // 跨域
  crossDomain: true
})

service.interceptors.request.use(
  async (config) => {
    // if (!process.browser) {
    //   console.log('-------------------')
    //   console.log('url: ', config.url)
    // }
    if (config.isLoading) {
      isCSR && showLoading()
    }
    if (config.abortConfig) {
      // 取消 没有权限 发起的请求
      removePendingRequest(config.url) // 检查是否存在重复请求，若存在则取消已发的请求
      config.cancelToken = new axios.CancelToken((cancel) => {
        // 存当前请求
        addPendingRequest(cancel, config.url)
      })
    }
    config.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    // 获取超时接口数据，添加请求时间戳
    config.headers.Ats = Date.now()
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
service.interceptors.response.use(
  (response) => {
    if (!process.browser) {
      // 获取超时接口数据，获取请求时间戳，超时上报
      const time = Date.now() - response.config.headers.Ats
      global.$logger.api(`[服务端请求${time > 80 ? '80' : '--'}]`, 'time:', time, 'url:', response.config.url, 'msg: ', response.data.err_msg, 'status: ', response.data.status)
    }

    const { config, data } = response

    // abort 配置的接口 从pendingRequest对象中移除
    config.abortConfig && removePendingRequest(config.url)

    const rs = data // eslint-disable-line
    if (config.isLoading) {
      isCSR && hideLoading()
    }

    // 单独判断index.php接口，为了在nuxtServerInit拿取cookie,并注入页面
    const requesUrl = response.request.path || response.request.responseURL || config.url || ''
    if (requesUrl?.indexOf('public_variables') > -1) {
      return response
    }

    // 如果 status ！== 0 抛异常
    if (rs.status && (Number(rs.status) !== 0)) {
      if (config.isAutoMsg) {
        const text = rs.err_msg || rs.errmsg ||  "未知错误"; // eslint-disable-line
        isCSR && message.warning(`${text || '未知异常，请重试'}`)
      }
      return Promise.reject(response)
    }
    return response.data
  },
  (error) => {
    if (!process.browser) {
      console.log('onResponseError: ')
      console.log(error.config.url, error.config.params)
    } else {
      // global?.$sentry?.captureException(error.error || error.message || error.originalError || error)
    }
    isCSR && hideLoading()
    let msg = '接口异常，请重试'
    if (error.code === 'ECONNABORTED') {
      msg = '接口超时，请重试'
    }
    error.message !== '取消请求' && isCSR && message.warning(`${msg}`)
    return Promise.reject(error.response || error)
  }
)

// 把组件引入，并定义成原型属性方便使用
Component.prototype.$axios = service

export default service
