import React from 'react'
import Router from 'next/router'
import PropTypes from 'prop-types'
import style from './index.scss'

import imgErr5 from '~/img/error/err5.png'
import imgErr4 from '~/img/error/err4.png'
// import { $cookie } from '../../utils/utils'

const Error = (props) => {
  const errCode = props.statusCode ? String(props.statusCode).match(/(\d)(\d+)/)[1] : 0
  // useEffect(() => {
  //   global.$sentryLog && global.$sentryLog({
  //     title: '进入错误页',
  //     info: {
  //       errCode: props.statusCode,
  //       uuid: $cookie.get('new_visitor_uuid')
  //     }
  //   })
  // }, [])
  let template = ''
  if (errCode === '5') {
    template = <img className="pic" src={imgErr5} alt="5xx" />
  } else if (errCode === '4') {
    template = <img className="pic" src={imgErr4} alt="4xx" />
  } else {
    template = <p className="txt">{props.statusCode}</p>
  }
  const onSetStore = () => {
    if (props.store && props.store.dispatch) {
      props.store.dispatch({ type: 'DEL_ERRCODE', data: { code: null } })
    }
  }
  const onReload = () => {
    onSetStore()
    Router.push(props.router.route, props.router.asPath)
  }


  return (
    <section className={style.error}>
      <div className="errimg">
        {template}
      </div>
      <ul className="errbtn_warp clearfix">
        <li onClick={onSetStore}>
          <a href="https://www.renrenche.com/">返回首页</a>
        </li>
        {errCode === '5' ? <li onClick={onReload}>重新加载</li> : ''}
      </ul>
    </section>
  )
}


Error.getInitialProps = ({ ctx }) => {
  try {
    const statusCode = ctx.res ? ctx.res.statusCode : ctx.err ? ctx.err.statusCode : 404
    !process.browser && console.log('ERRORCODE----', ctx?.asPath)
    return { statusCode }
  } catch (error) {
    throw error
  }
}

// 指定 props 的默认值：
Error.defaultProps = {
  statusCode: 200,
  store: null,
  router: null
}
Error.propTypes = {
  statusCode: PropTypes.any,
  store: PropTypes.any,
  router: PropTypes.any
}

export default Error
