// ---------帮买---------

// 车龄要求
export const carAgeOptions = [
  {
    key: "",
    label: "不限车龄"
  },
  {
    key: '1',
    label: "1年以内"
  },
  {
    key: '2',
    label: "2年以内"
  },
  {
    key: '3',
    label: "3年以内"
  },
  {
    key: '4',
    label: "4年以内"
  },
  {
    key: '5',
    label: "5年以内"
  },
  {
    key: '6',
    label: "6年以内"
  },
  {
    key: '7',
    label: "7年以内"
  },
  {
    key: '8',
    label: "8年以内"
  },
  {
    key: '0',
    label: "不限"
  }
]
export const timeLimitOptions = [
  "1~2周内",
  "1个月内",
  "2个月内",
  "半年内",
  "先看看不着急"
]
