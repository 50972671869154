// url中需要初始化的字段，含分页排序等
export const filterPathKeys = [
  "price",
  "car_grade",
  "car_type",
  "age",
  "mileage",
  "displacement",
  "emission",
  "brand_country",
  "car_drive",
  "fuel_type",
  "car_color",
  "star_config",
  "carload",
  "special_tags",
  "sort",
  "seq",
  "start",
  "rows",
  "brand",
  "car_series",
  "gearbox",
  "wd"
]
// 筛选器结果展示的字段
export const filterResutKeys = [
  "price",
  "car_grade",
  "car_type",
  "age",
  "mileage",
  "displacement",
  "emission",
  "brand_country",
  "car_drive",
  "fuel_type",
  "car_color",
  "star_config",
  "carload",
  "special_tags",
  "brand",
  "car_series",
  "gearbox"
]
// 品牌 车系
export const brandsSeriesMap = [
  {
    key: "brand",
    name: "品牌"
  },
  {
    key: "car_series",
    name: "车系"
  }
]
// 更多筛选项
export const showMultMap = [
  {
    key: "car_grade",
    name: "车辆级别"
  },
  {
    key: "car_type",
    name: "车辆类型"
  },
  {
    key: "age",
    name: "车龄"
  },
  {
    key: "gearbox",
    name: "变速箱"
  },
  {
    key: "mileage",
    name: "里程"
  },
  {
    key: "displacement",
    name: "排量"
  },
  {
    key: "emission",
    name: "排放标准"
  },
  {
    key: "brand_country",
    name: "国别"
  },
  {
    key: "car_drive",
    name: "驱动"
  },
  {
    key: "fuel_type",
    name: "燃料类型"
  },
  {
    key: "car_color",
    name: "颜色"
  },
  {
    key: "star_config",
    name: "亮点配置"
  },
  {
    key: "carload",
    name: "座位"
  }
]
// tag
export const tagOptions = [
  {
    name: "随时看",
    value: "ssk"
  },
  {
    name: "急售",
    value: "ur"
  },
  {
    name: "超值",
    value: "gr"
  },
  {
    name: "准新车",
    value: "al"
  },
  {
    name: "促销车",
    value: "dis"
  },
  {
    name: "新上",
    value: "new"
  }
]

/** @desc 获取search字段key缩写 */
export const getSearchShortKey = (key) => {
  switch (key) {
    case "price":
      return "pr"
    case "level":
      return "le"
    case "age":
      return "ag"
    case "gearbox":
      return "ge"
    case "mileage":
      return "mi"
    case "displacement":
      return "di"
    case "emission":
      return "em"
    case "brand_country":
      return "bc"
    case "car_drive":
      return "dr"
    case "fuel_type":
      return "ft"
    case "car_color":
      return "co"
    case "star_config":
      return "sc"
    case "carload":
      return "cl"
    default:
      return key
  }
}

/** @desc 获取search字段key */
export const getSearchLongKey = (key) => {
  switch (key) {
    case "pr":
      return "price"
    case "le":
      return "level"
    case "ag":
      return "age"
    case "ge":
      return "gearbox"
    case "mi":
      return "mileage"
    case "di":
      return "displacement"
    case "em":
      return "emission"
    case "bc":
      return "brand_country"
    case "dr":
      return "car_drive"
    case "ft":
      return "fuel_type"
    case "co":
      return "car_color"
    case "sc":
      return "star_config"
    case "cl":
      return "carload"
    default:
      return key
  }
}
/**
 * @desc 根据url获取筛选器初始化对象
 * @param {} url
 * @returns
 */
export const getSearchObj = (url) => {
  const tempObj = {}
  const getVal = (key, val) => {
    if (!key || !val) {
      return
    }
    if (["start", "rows"].indexOf(key) > -1) {
      return Number(val)
    } else if (["pr", "seq", "sort", "brand", "car_series"].indexOf(key) > -1) {
      return val
    } else {
      return val.split("_")
    }
  }
  for (const [key, value] of new URLSearchParams(url).entries()) {
    tempObj[getSearchLongKey(key)] = getVal(key, value)
  }
  return tempObj
}

/**
 * @desc 根据筛选器结果获取name
 */
export const getSelectedLabel = (key, searchObj, filterOptions) => {
  // 不展示的项：排序、分页
  if (["seq", "sort", "start", "rows"].indexOf(key) > -1) {
    return []
  }
  // 品牌和车系只有一项，转化成数组
  if (["brand", "car_series"].indexOf(key) > -1) {
    return [searchObj[key]]
  }

  // 自定义价格，结果填充
  if (["price"].indexOf(key) > -1) {
    if (!(searchObj?.price && searchObj?.price[0])) {
      return []
    }
    const priceItem = filterOptions[key]?.list?.find(
      o => o.value === searchObj["price"][0]
    )
    if (priceItem) {
      return [priceItem]
    } else {
      const curPrice =
        searchObj?.price &&
        searchObj?.price[0]?.split("-").sort((a, b) => a - b)
      return [
        {
          name: `${curPrice[0]}-${curPrice[1]}万`,
          value: `${curPrice[0]}-${curPrice[1]}`
        }
      ]
    }
  }

  if (filterOptions[key] && filterOptions[key].list) {
    return (searchObj[key] || []).map(e => filterOptions[key].list.find(o => o.value === e))
  }

  return []
}
/**
 *
 * @param {*} searchObj
 * @returns 构建path字符串
 */
export const initSearchStr = (searchObj) => {
  const searchParams = new URLSearchParams()
  for (const key in searchObj) {
    if (searchObj[key] instanceof Array) {
      searchObj[key].length
        ? searchParams.set(key, searchObj[key].join("_"))
        : searchParams.delete(key)
    } else {
      searchObj[key] && searchParams.set(key, searchObj[key])
    }
  }
  searchParams.sort()
  return decodeURIComponent(searchParams.toString())
}

/**
 * @desc 是否展示筛选器结果
 */
export const showSelectedResult = searchObj => Object.keys(searchObj)
    .filter(k => filterResutKeys.indexOf(k) > -1)
    .filter(y => searchObj[y]?.length > 0).length > 0
