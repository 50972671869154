const pendingRequest = new Map()

export const addPendingRequest = (cancel, abortKey) => {
  pendingRequest.set(abortKey, cancel)
}

export const removePendingRequest = (abortKey) => {
  if (!pendingRequest.has(abortKey)) {
    return
  }
  const cancel = pendingRequest.get(abortKey)
  cancel('取消请求')
  pendingRequest.delete(abortKey)
}
