import { get, post } from '$fetch'

/**
 * @desc 买卖车400电话获取
 * @param {*} params
 */
export const checkCode = (data = {}) => post('/lurker/antispam/check', data)

/**
 * 获取交易须知
 * @param {*} data
 */
export const getWarningMsg = (data = {}) => get('/xcx/getwarningmsg', data)
