import { get, post } from '$fetch'

/**
 * @desc 全局数据、cookie获取
 * @param {*} city: url中的城市abbr
 * @param {*} fr: url中的fr
 * @returns
 */
export const getAllBrands = params => get('/lurker/v1/car/get_brand?type=pc', { ...params }, { isAutoMsg: false, isLoading: false })
/**
 * @desc 获取省份下城市
 * @param {*} province: 省份
 * @returns
 */
export const getCityList = params => get('/lurker/city/getCityByProvince', params, { isAutoMsg: false })

/**
   * @desc 保卖预约，图形验证码校验
   * @param {*} param vcode|city|mobile_number|submit_sources 预约卖车-PC-保卖服务页-立即预约
   * @returns
   */
export const orderSellCheck = param => post('/lurker/v1/intent/sale/c1', param, { isAutoMsg: false })

/**
   * @desc 卖车页c1补充信息
   * @param {*} param vcode|city|mobile_number|submit_sources 预约卖车-PC-保卖服务页-立即预约
   * @returns
   */
export const sellSupplement = param => post('/lurker/v1/intent/sale/c1_supplement', param, { isAutoMsg: false })

/**
 * @desc 获取短信验证码
 * @param {*} phone: 手机号
 * @param {*} city: 城市
 * @param {*} channel: fr
 * @param {*} __jwtsignature: jwtsignature
 * @returns
 */
 export const getSellBanner = data => get('/lurker/v1/intent/sale/banner?type=pc', {
   ...data, typ: '1', os: 'pc'
 }, { isApiHost: true, isFormData: false, isAutoMsg: false })
