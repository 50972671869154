import { Button, Tag } from "antd"
import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import LazyLoad from "react-lazyload"
import styles from "./index.scss"
import { UseIntentOrder } from "../../detail/[id]/modules/intent-modal/order"

const carItem = (props) => {
  // 车源列表数据
  const { carObj } = props
  // 是否新上-2天内24*2*60*60*1000=172800000
  const isNew = () => Date.now() - Date.parse(carObj.publish_time) < 172800000
  const initImgUrl = url => url
      .replace("rrcdn.qiniudn.com", "img2.rrcimg.com")
      .replace("static.renrenche.com", "img2.rrcimg.com")
      .replace("http://", "//")
  // 免费咨询
  const toConsult = (e) => {
    global.$rbl.push({
      event: 'search_carlist-page_search-carlist-button-免费咨询',
      content: { param: "咨询-PC-列表页-1" }
    })
    e.preventDefault()
    e.stopPropagation()
    const submitParams = {
      mobile_number: "",
      phone_source: "",
      sub_product: "咨询",
      submit_sources: "咨询-PC-详情页-1",
      car_id: carObj.id
    }
    const otherParams = {
      ino: 0,
      basicInfo: {
        ...carObj
      }
    }
    new UseIntentOrder({
      intentType: "consult",
      submitParams,
      otherParams,
      submitGa: 'search_submit'
    }).open()
  }

  return (
    <div
      key={carObj.id}
      className={`${styles["r-search-car-item"]} ${props.wrapperClass}`}
    >
      <a href={`/buy/detail/${carObj.id}`} target="_blank">
        <LazyLoad offset={400} height={322}>
          <>
            {carObj.discount > 0 && !carObj.youxuan_discount_title && (
              <div className="discount">
                已降
                <br />
                {carObj.discount < 1
                  ? `${(carObj.discount * 10000).toFixed(0)}元`
                  : `${carObj.discount}万`}
              </div>
            )}
            {isNew() && (
              <div className="new">
                <span className="text">新</span>
              </div>
            )}
            <div className="img">
              <img
                src={`${initImgUrl(
                  carObj.search_image_url
                )}?imageView2/2/interlace/1/w/360/h/240/ignore-error/1/format/jpg`}
                data-title={carObj.title}
                alt={carObj.title}
              />
              {carObj.city !== carObj.licensed_city && (
                <div className="position-bg-n">{carObj.licensed_city}车牌</div>
              )}

              {carObj.rrc_tags && carObj.rrc_tags.indexOf(1) > -1 && (
                <div className="video-icon">
                  <div className="triangle" />
                </div>
              )}
              {/* 有视频就不展示vr */}
              {!(carObj.rrc_tags && carObj.rrc_tags.indexOf(1) > -1) &&
                carObj.display_url && (
                  <div className="vr-icon">
                    <div className="panorama-animation" />
                  </div>
                )}
            </div>
            <h3>
              {carObj.sell_on_consignment > 0 && (
                <img
                  className="consignment"
                  src="https://img1.rrcimg.com/严选@1x.png"
                  alt=""
                />
              )}
              {carObj?.tag_ahead_of_title?.img_url && (
                <img
                  className="tag"
                  src={carObj.tag_ahead_of_title.img_url}
                  alt=""
                />
              )}
              {carObj.title}
            </h3>
            <div className="mileage">
              <span className="basic">
                {moment(carObj.licensed_date).format("YYYY年MM月")}/
                {carObj.mileage}万公里/
                {carObj.city}
              </span>
            </div>
            <div className="mileage-tag-box">
              {carObj?.tags?.map(t => (
                <Tag color={t.color} key={t.key}>
                  {t.txt}
                </Tag>
              ))}
            </div>
            <div className="price-box">
              <div className="price">
                {carObj.price ? Number(carObj.price).toFixed(2) : '?'}
                <span>万</span>
              </div>
              <Button className="consult" onClick={e => toConsult(e)}>
                免费咨询
              </Button>
            </div>
          </>
        </LazyLoad>
      </a>
    </div>
  )
}
export default carItem

carItem.propTypes = {
  wrapperClass: PropTypes.string
}

carItem.defaultProps = {
  wrapperClass: ""
}
