import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, message } from 'antd'
import ImgVerify from '@/components/rc-img-verify'
import styles from  './index.scss'
import {
  isPhone, isCode, $hooks
} from '$utils'
import { getsmscode } from '$service'

const SmsCodeVerify = (props) => {
  const [smsVerifyCode, setSmsVerifyCode] = useState()
  const [smsVerifyCodeValid, setSmsVerifyCodeValid] = useState(false)
  const [isGettingSmsCode, setIsGettingSmsCode] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  // 图形验证码
  const [imgVerify, setImgVerify] = useState()
  const [imgVerifyCode, setImgVerifyCode] = useState()

  // 短信验证码
  useEffect(() => {
    if (smsVerifyCodeValid) {
      props.onSmsCode && props.onSmsCode(smsVerifyCode)
    }
  }, [smsVerifyCodeValid])

  // 校验信息
  useEffect(() => {
    if (errMsg) {
      props.onErrMsg && props.onErrMsg(errMsg)
    }
  }, [errMsg])


  // 获取短信验证码的倒计时
  const [seconds, setSeconds] = useState(59)
  const [id, start] = $hooks.useInterval(() => {
    if (seconds > 1) {
      setSeconds(seconds - 1)
    } else {
      clearInterval(id)
      setIsGettingSmsCode(false)
    }
  }, 1000)

  // 获取短信验证码
  const getSmscodeHandler = async () => {
    // 手机号校验
    if (!isPhone(props.phone)) {
      setErrMsg('手机号输入有误')
      return
    }
    setErrMsg('')

    try {
      const param = {
        phone: props.phone,
        code: smsVerifyCode,
        city: window.store.getState().city.city
      }
      if (imgVerify && imgVerifyCode && isCode(imgVerifyCode)) {
        param.code = imgVerifyCode
      }
      await getsmscode(param)
      // 倒计时60s
      setSeconds(59)
      start()
      setIsGettingSmsCode(true)
      // 组件状态
      setImgVerify()
      setImgVerifyCode()
    } catch (error) {
      console.log(error)
      if (error.status === 200) {
        const res = error.data
        if (res && res.status === 400) {
          setImgVerify(res.data.image)
          setImgVerifyCode()
        } else if (res && res.status === 5100) {
          message.warn(res.err_msg || '操作频繁，稍后再试')
        } else {
          message.warn(res.err_msg || '未知错误')
        }
      } else {
        message.warn(error.err_msg)
      }
    }
  }

  // 图形验证码自动提交
  useEffect(() => {
    if (imgVerify && imgVerifyCode && isCode(imgVerifyCode)) {
      getSmscodeHandler()
    }
  }, [imgVerifyCode])

  // 是否自动发送验证码
  useEffect(() => {
    if (props.smsVerify && props.autoSend === true) {
      getSmscodeHandler()
    }
  }, [props.autoSend, props.smsVerify])

  return (
    <>
      {/* 防刷短信验证码 */}
      {props.smsVerify
    && (
    <div className="rc-verify">
      {/* 防刷图形验证码 */}
      <ImgVerify
        wrapperClassName={props.wrapperClassName}
        imgVerify={imgVerify}
        imgVerifyCode={imgVerifyCode}
        onImgVerifyCode={(code) => {
          setImgVerifyCode(code)
        }}
        onRefresh={() => {
          getSmscodeHandler(true)
        }}
      />

      <div className={`${styles['rc-sms-verify']} ${props.wrapperClassName}`}>
        <Input
          placeholder="短信验证码"
          bordered="false"
          maxLength={4}
          value={smsVerifyCode}
          onInput={(e) => {
            setSmsVerifyCode(e.target.value)
            if (e.target.value) {
              setSmsVerifyCodeValid(isCode(e.target.value))
            }
          }}
        />
        {isGettingSmsCode ? (
          <Button
            className="getsmscode-btn getsmscode-btn--disabled"
            disabled
          >
            <span className="seconds">{seconds}</span>秒后可重发
          </Button>
        ) : (
          <Button
            className="getsmscode-btn getsmscode-btn--default"
            onClick={getSmscodeHandler}
          >
            获取验证码
          </Button>
        )}
      </div>
    </div>
    )
    }
    </>
  )
}

export default SmsCodeVerify

SmsCodeVerify.propTypes = {
  phone: PropTypes.string,
  onSmsCode: PropTypes.func,
  onErrMsg: PropTypes.func,
  smsVerify: PropTypes.string,
  wrapperClassName: PropTypes.string,
  autoSend: PropTypes.bool
}

SmsCodeVerify.defaultProps = {
  phone: '',
  onSmsCode: () => ({}),
  onErrMsg: () => ({}),
  smsVerify: '',
  wrapperClassName: '',
  autoSend: false
}
