/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  Modal, Input, Button, Checkbox, Cascader, message
} from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import md5 from 'md5'
import { intentOrderEdit, getCityList } from '../service'
import { UseModalSuccess } from '../success'
import provinceConfig from './provinceConfig'
import styles from './styles.scss'
import {
  floatAdd, floatSubtract, floatMultiple, $cookie, sessionstorage
} from '$utils'
import $dom from '../util'

// 预约看车弹窗
const IntentOrderExt = (props) => {
  moment.locale('zh', {
    weekdays: ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
  })
  const {
    onClose, profile, submitParams, otherParams, modelName
  } = props
  const { basicInfo } = otherParams
  const [visible, setVisible] = useState(props.visible)
  const [profiles, setProfiles] = useState({
    common_address: profile?.common_address,
    licensed_city: profile?.licensed_city,
    expected_appointment_time: profile?.expected_appointment_time,
    is_optional_appointment_datetime: 0,
    is_local_index: 0,
    need_installment: 2
  })
  const [isAllowClear, setIsAllowClear] = useState(false)
  useEffect(() => {
    setIsAllowClear(profiles?.licensed_city?.length > 0)
  }, [profiles?.licensed_city])
  const commonAddressRef = useRef()
  const [showCommonAddressErrMsg, setShowCommonAddressErrMsg] = useState(false)
  const [
    showExpectedAppointmentTimeErrMsg,
    setShowExpectedAppointmentTimeErrMsg
  ] = useState(false)
  const titleTxt = {
    bargain: '砍价成功立即帮您约车',
    discount_notify: '降价后立即帮您约车',
    time_limit_appointment: '约车速度更快',
    appointment: '约车速度更快',
    consult: '车况合适立即帮您约车'
  }
  const owner = {
    city: sessionstorage.get('ownercity') || '',
    address: sessionstorage.get('owneraddress')
  }

  const c2AddGa = (event) => {
    const params = {
      event,
      content: {
        pageModule: 'car-detail_c2-add',
        param: submitParams.submit_sources,
        expand: {
          value: 'c2预约信息',
          submit_sources: submitParams.submit_sources,
          sub_product: submitParams.model_name
        }
      }
    }
    global.$rbl.push(params)
  }

  // 是否展示调价按钮
  const isShowAddPrice = (modelName === 'discount_notify' || modelName === 'bargain') && (Number(submitParams.price) < floatMultiple(submitParams.owner_price, 0.9))
  const [intentionPrice, setIntentionPrice] = useState(Number(submitParams.price) || 0)
  const [errMsg, setErrMsg] = useState('')
  const [isDisabledOne, setIsDisabledOne] = useState(false)
  const [isDisabledTwo, setIsDisabledTwo] = useState(false)
  const [isDisabledThree, setIsDisabledThree] = useState(false)
  const setAddPrice = (price) => {
    setIntentionPrice(floatAdd(intentionPrice, price))
  }
  useEffect(() => {
    const _isDisabledOne = floatSubtract(Number(basicInfo?.price), intentionPrice) < 0.1
    const _isDisabledTwo = floatSubtract(Number(basicInfo?.price), intentionPrice) < 0.2
    const _isDisabledThree = floatSubtract(Number(basicInfo?.price), intentionPrice) < 0.3
    setIsDisabledOne(_isDisabledOne)
    setIsDisabledTwo(_isDisabledTwo)
    setIsDisabledThree(_isDisabledThree)
    if (intentionPrice > submitParams.owner_price) {
      c2AddGa('pc-car-detail-c2-add-error- 您的意向价格已超过原价，请修改')
      setErrMsg('您的意向价格已超过原价，请修改')
    } else {
      setErrMsg('')
    }
  }, [intentionPrice])

  const orderTimes = [
    { key: '8:00-10:00', value: '08:00:00' },
    { key: '10:00-12:00', value: '10:00:00' },
    { key: '12:00-14:00', value: '12:00:00' },
    { key: '14:00-16:00', value: '14:00:00' },
    { key: '16:00-18:00', value: '16:00:00' },
    { key: '18:00-20:00', value: '18:00:00' }
  ]
  // 初始化时间数据
  const initOrderDayObj = () => {
    const hour = moment(basicInfo.unixtime).format('HH')
    const n = (hour - 6) / 2
    const canTimeChecked = (day, time, i) => !(i < n)
    const initTimesOption = (day, initflag) => orderTimes.reduce((p, c, i) => {
      const _c = { ...c }
      _c.canChecked = initflag ? canTimeChecked(day, _c.value, i) : true
      _c.isChecked = false
      p.push(_c)
      return p
    }, [])
    const _days = [
      {
        label: `明天(${moment(basicInfo.unixtime).add(1, 'day').format('dddd')})`,
        day: moment(basicInfo.unixtime).add(1, 'day').format('YYYY-MM-DD'),
        times: initTimesOption(moment(basicInfo.unixtime).add(1, 'day').format('YYYY-MM-DD'))
      }
    ]
    if (moment(basicInfo.unixtime).format('HH') <= 16) {
      _days.unshift({
        label: `今天(${moment(basicInfo.unixtime).format('dddd')})`,
        day: moment(basicInfo.unixtime).format('YYYY-MM-DD'),
        times: initTimesOption(moment(basicInfo.unixtime).format('YYYY-MM-DD'), true)
      })
    } else {
      _days.push({
        label: `后天(${moment(basicInfo.unixtime).add(2, 'day').format('dddd')})`,
        day: moment(basicInfo.unixtime).add(2, 'day').format('YYYY-MM-DD'),
        times: initTimesOption(moment(basicInfo.unixtime).add(2, 'day').format('YYYY-MM-DD'))
      })
    }
    return _days
  }

  const [orderDayObj, setOrderDayObj] = useState(initOrderDayObj()) // 可选时间段结构
  const [otherTime, setOtherTime] = useState(false) // 是否选择其它时间
  const [paramDay, setParamDay] = useState()


  const [options, setOptions] = useState(provinceConfig) // 省份数据
  // 选择省份获取城市
  const loadCity = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true

    const params = {
      province: targetOption.value
    }
    const { data: cityList } = await getCityList(params)
    targetOption.children = cityList
      .sort((a, b) => a.localeCompare(b, 'zh-CN'))
      .map(city => ({ value: city, label: city }))
    targetOption.loading = false
    setOptions([...options])
  }


  useEffect(() => {
    if (visible === true) {
      c2AddGa('car-detail_reservation_display')
    }
  }, [visible])
  // 预约看车信息补充 提交
  const orderEditSubmit = async () => {
    c2AddGa('car-detail_c2-add_提交')
    global.$rbl.push(
      {
        event: 'appointment_edit',
        content: {
          pageModule: 'car-detail_c2-add',
          param: md5(submitParams?.mobile_number || submitParams?.phone),
          expand: {
            submit_sources: submitParams.submit_sources,
            sub_product: modelName
          }
        }
      }
    )
    // 必填信息校验
    setShowCommonAddressErrMsg(false)
    setShowExpectedAppointmentTimeErrMsg(false)
    // GA err
    if (!profiles?.common_address?.length) {
      c2AddGa('pc-car-detail-c2-add-error- 请填写常用地址')
    }
    if (!otherTime && !profiles?.expected_appointment_time?.length) {
      c2AddGa('pc-car-detail-c2-add-error- 请选择看车时间')
    }
    if (!profiles?.licensed_city?.length) {
      c2AddGa('pc-car-detail-c2-add-error- 请选择上牌城市')
    }
    if (!profiles?.common_address?.length) {
      setShowCommonAddressErrMsg(true)
      commonAddressRef.current?.focus()
      return false
    }
    if (!otherTime && !profiles?.expected_appointment_time?.length) {
      setShowExpectedAppointmentTimeErrMsg(true)
      return false
    }
    if (errMsg) {
      return false
    }
    // 后端处理number数据会报错，改成string类型
    const params = {
      ...submitParams,
      ...profiles,
      need_installment: `${profiles.need_installment}`,
      is_local_index: `${profiles.is_local_index}`,
      is_optional_appointment_datetime: `${
        profiles?.expected_appointment_time?.length > 0 ? '0' : '1'
      }`
    }
    if (params.owner_price) {
      params.owner_price = `${submitParams.owner_price}`
    }
    if (submitParams.price) {
      params.price = `${intentionPrice || submitParams.price}`
    }
    if (params?.expected_appointment_time?.length > 0) {
      params.expected_appointment_time += ','
    } else {
      params.expected_appointment_time = '0000-00-00 00:00:00'
    }
    if (params.phone_source) {
      delete params.phone_source
    }
    if (submitParams.model_name === 'discount_notify') {
      params.sub_product = 'discount_notify'
    }
    try {
      const result = await intentOrderEdit(params)
      let successParam = null
      // 国四
      if (result.data.emission) {
        c2AddGa('pc-car-detail-c2-add-success')
        successParam = {
          codeType: 'detail_appoint',
          title: '预约成功',
          msg: '我们会尽快联系您，确认看车事宜',
          c2Param: {
            address: `${owner?.city || ''}${owner?.address}`,
            paramDay: Object.values(paramDay || {}),
            basicInfo
          }
        }
      } else {
        const emArr = {
          国二: '2_3_4_5',
          国三: '3_4_5',
          国四: '4_5',
          国五: '5'
        }
        const title = `${profiles.licensed_city}上牌标准为${result.data.city_emission}${result.data.city_emission !== '国五' ? '以上' : ''}`
        const msg = `该车无法在${profiles.licensed_city}上牌，我们会尽快联系您，确认上牌城市`
        successParam = {
          codeType: 'detail_appoint',
          title,
          msg,
          emission: {
            msg: `去看${result.data.city_emission}${result.data.city_emission !== '国五' ? '以上' : ''}的车>>`,
            link: `/${$dom.getDetailData('cityAbbr')}/ershouche?em=${emArr[result.data.city_emission]}`
          }
        }
        c2AddGa('pc-car-detail-c2-add-fail')
      }

      const showSuccess = () => {
        new UseModalSuccess(successParam).open()
      }
      // 未登录提交信息补充后，应该弹出登录弹窗，并发送短信验证码
      if (!props.isSmsVerify && !$cookie.get('rrc_login_phone') && !$cookie.get('rrc_login_token')) {
        // 存储弹窗信息，跳转登录返回后执行
        sessionstorage.set('loginBackModalInfo', successParam)
        window.location.href = `${
          window.location.origin
        }/login?source=${encodeURIComponent(window.location.href)}&phone=${params?.phone || ''}`
        // new UseLogin({
        //   isAutoMsg: false,
        //   isAutoLogin: true,
        //   phone: submitParams?.mobile_number || submitParams?.phone,
        //   onSuccessCallBack: () => {
        //     showSuccess()
        //   }
        // }).open()
      } else {
        showSuccess()
      }
      c2AddGa('car-detail_c2-add_关闭')
      setVisible(false)
      onClose && onClose()
    } catch (error) {
      console.log(error)
      message.error(error?.data?.err_msg || error?.data?.errMsg || '未知错误')
    }
    return null
  }
  // 选择其它时间

  useEffect(() => {
    if (otherTime === true) {
      setProfiles({
        ...profiles,
        expected_appointment_time: '',
        is_optional_appointment_datetime: otherTime ? '1' : '0'
      })
      setOrderDayObj(initOrderDayObj())
    }
  }, [otherTime])

  // 选择看车时间点击事件
  const timeClick = (day, index, time, index2) => {
    setOtherTime(false)
    c2AddGa(`car-detail_c2-add_${day.label}-${time.key}-${
      orderDayObj[index].times[index2].isChecked ? '取消' : '选择'
    }`)
    orderDayObj[index].times[index2].isChecked = !orderDayObj[index].times[index2].isChecked
    setOrderDayObj([...orderDayObj])
    const _paramDays = []
    const checkedDayTimes = orderDayObj.reduce((p, c) => {
      c.times.map((_time) => {
        if (_time.canChecked && _time.isChecked) {
          if (!_paramDays[c.label]) {
            _paramDays[c.label] = {
              day: c.day,
              label: c.label,
              times: []
            }
          }
          _paramDays[c.label].times.push(_time.value)
          p.push(`${c.day} ${_time.value}`)
        }
        return null
      })
      return p
    }, [])
    setParamDay(_paramDays)

    setProfiles({
      ...profiles,
      expected_appointment_time: checkedDayTimes.join(','),
      is_optional_appointment_datetime: checkedDayTimes.length > 0 ? 0 : 1
    })
  }

  return (
    <Modal
      bodyStyle={{ padding: '0px' }}
      visible={visible}
      width={860}
      onCancel={() => {
        c2AddGa('car-detail_c2-add_关闭')
        setVisible(false)
        onClose && onClose()
      }}
      centered
      footer={null}
    >
      <div className={styles.intentModalExt}>
        <p className="title">
          <i className="iconfont icon-weimingming2" /> 补充看车信息，
          <span className="highlight">
            {titleTxt[modelName] || '约车速度更快'}
          </span>
        </p>
        <div className="car-info clearfix">
          <div className="car-img">
            <img
              src={`${basicInfo?.search_image_url}?imageView2/2/interlace/1/w/180/h/120/format/jpg`}
              alt=""
            />
          </div>
          <div className="car-basic">
            <h3 className="car-title">{basicInfo?.title}</h3>
            <p className="car-price">
              卖家报价：<span className="owner-price">{basicInfo?.price}万</span>新车{basicInfo?.newcar_price}万+税
              {basicInfo?.tax}万
            </p>
            <p className="car-location">
              当前车辆位置
              <i className="iconfont icon-dingwei1" />
              <span className="address">
                {owner?.city ? `${owner?.city}-` : ''}
                {owner?.address}
              </span>
              <span className="tip">(以实际看车地点为准)</span>
            </p>
          </div>
        </div>
        <div className="order-info">
          <div className="form">
            <div className="form-item clearfix">
              <span className="label">
                <span className="required">*</span>我的常用地址
              </span>
              <Input
                onClick={() => { c2AddGa('car-detail_c2-add_常用地址') }}
                ref={commonAddressRef}
                value={profiles.common_address}
                className="value"
                onChange={(v) => {
                  setProfiles({ ...profiles, common_address: v.target.value })
                }}
                placeholder="请精确到街道或小区名"
              />
              <span className="tip">(帮您规划看车路线)</span>
              {showCommonAddressErrMsg ? (
                <span className="tip required">请填写常用地址</span>
              ) : (
                <></>
              )}
            </div>
            <div className="form-item clearfix">
              <span className="label">
                <span className="required">*</span>我上牌的城市
              </span>
              <Cascader
                allowClear={isAllowClear}
                onClick={() => { c2AddGa('car-detail_c2-add_我的上牌城市') }}
                className="value"
                size="large"
                placeholder="请选择上牌城市"
                options={options}
                loadData={loadCity}
                onChange={(v) => {
                  setProfiles({ ...profiles, licensed_city: v[1] })
                }}
                defaultValue={[profiles.licensed_city]}
              />
            </div>
            <div className="form-item">
              <span className="label time">
                <span className="required">*</span>请选择看车时间
                <span className="tip">(多选更容易约到车)</span>
              </span>
              {showExpectedAppointmentTimeErrMsg ? (
                <span className="tip required">请选择看车时间</span>
              ) : (
                <></>
              )}
              <table className="inspect-table">
                <tbody>
                  <tr>
                    <td />
                    {orderTimes.map((time, index) => (
                      <td
                        key={index}
                        className={`${
                          orderDayObj.filter(d => d.times[index].isChecked)
                            .length > 0
                            ? 'title-checked'
                            : ''
                        }`}
                      >
                        {time.key}
                      </td>
                    ))}
                  </tr>
                  {orderDayObj.map((day, index) => (
                    <tr key={index}>
                      <td
                        className={`${
                          day.times.filter(t => t.isChecked).length > 0
                            ? 'title-checked'
                            : ''
                        }`}
                      >
                        {day.label}
                      </td>
                      {day.times.map((time, index2) => (
                        <td
                          key={index2}
                          className={`time ${
                            time.canChecked === true ? 'can-checked' : ''
                          }${
                            time.canChecked === true && time.isChecked === true
                              ? ' is-checked'
                              : ''
                          }`}
                          time={time.value}
                          onClick={() => {
                            time.canChecked && timeClick(day, index, time, index2)
                          }}
                        >
                          {time.canChecked === true ? (
                            time.isChecked ? (
                              <i className="iconfont icon-hehuoren_v8_2" />
                            ) : (
                              '可选'
                            )
                          ) : (
                            '不可选'
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="7" className="ask-time">
                      <Checkbox
                        className="check-box"
                        checked={otherTime}
                        onChange={(e) => {
                          c2AddGa(`car-detail_c2-add_其他时间-${e.target.checked ? '选择' : '取消'}`)
                          setOtherTime(e.target.checked)
                        }}
                      />
                      以上时间均不合适？让工作人员和我预约其他时间
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* 砍价、降价提醒的调价按钮 */}
              {isShowAddPrice && (
                <div className="c2-add-price">
                  <div className="price-name">我的意向价格</div>
                  <input
                    className="price-input info-input"
                    value={intentionPrice}
                    onChange={(e) => {
                      setIntentionPrice(Number(e.target.value))
                    }}
                    onInput={() => {
                      c2AddGa('car-detail_c2-add_调价输入框')
                    }}
                  />
                  <div className="price-unit">万</div>
                  <span className="notes">提高出价，更容易约到车</span>
                  <Button
                    type="primary"
                    ghost
                    disabled={isDisabledOne}
                    onClick={() => {
                      setAddPrice(0.1)
                      c2AddGa('car-detail_c2-add_调价按钮1')
                    }}
                    className="price-add"
                  >
                    +1000元
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    disabled={isDisabledTwo}
                    onClick={() => {
                      setAddPrice(0.2)
                      c2AddGa('car-detail_c2-add_调价按钮2')
                    }}
                    className="price-add"
                  >
                    +2000元
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    disabled={isDisabledThree}
                    onClick={() => {
                      setAddPrice(0.3)
                      c2AddGa('car-detail_c2-add_调价按钮3')
                    }}
                    className="price-add"
                  >
                    +3000元
                  </Button>
                  {errMsg && <p className="err-msg">{errMsg}</p>}
                </div>
              )}
            </div>
            <Button
              type="primary"
              className="submit-btn"
              onClick={orderEditSubmit}
            >
              提交
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

IntentOrderExt.propTypes = {
  visible: PropTypes.bool,
  isSmsVerify: PropTypes.bool,
  onClose: PropTypes.func,
  profile: PropTypes.object,
  submitParams: PropTypes.object,
  otherParams: PropTypes.object,
  modelName: PropTypes.string
}

IntentOrderExt.defaultProps = {
  visible: false,
  onClose: null,
  profile: null,
  submitParams: null,
  otherParams: null,
  modelName: 'appointment',
  isSmsVerify: false
}

function mountAnywhere(Comp, root) {
  const div = document.createElement('div');
  (root || document.body).appendChild(div)

  // 向组件注入 onClose 方法，以便组件能调用关闭
  const Clone = React.cloneElement(Comp, {
    onClose: () => {
      ReactDOM.unmountComponentAtNode(div)
      div.parentNode.removeChild(div)
    }
  })
  ReactDOM.render(Clone, div)

  return div
}

export class UseIntentOrderExt {
  constructor(params) {
    this.visible = false
    this.ref = null
    this.submitParams = params.submitParams
    this.otherParams = params.otherParams
    this.profile = params.profile
    this.modelName = params.modelName
    this.isSmsVerify = params.isSmsVerify
  }

  open() {
    this.visible = true
    this.ref = mountAnywhere(
      <IntentOrderExt
        visible={this.visible}
        profile={this.profile}
        submitParams={this.submitParams}
        otherParams={this.otherParams}
        modelName={this.modelName}
        isSmsVerify={this.isSmsVerify}
      />
    )
    return this.ref
  }

  close() {
    this.visible = false
  }
}
export default IntentOrderExt
