import { get, post } from '$fetch'
import { $cookie, sessionstorage } from '$utils'
import $dom from './util'

// 线索提交成功，未登录的操作
const afterIntentSubmit = (params) => {
  // 未登录，保存提交线索的手机号，下次自动填充
  sessionstorage.set('intentPhone', params?.mobile_number || params?.phone || '')
}
// 基本参数获取
export const getParamsDefault = () => ({
  device: 'pc',
  type: 'PC',
  city: $dom.getDetailData('cityName'),
  token: $cookie.get('rrc_login_token'),
  car_id: sessionstorage.get('car_id'),
  ss: $cookie.get('rrc_ss'),
  fr: $dom.getDetailData('fr')
})

// 第一次线索提交埋点上报
const intentFirstSubmitTimeGa = (params) => {
  const pageLoadTime = sessionstorage.get('pageLoadTime')
  if (pageLoadTime && pageLoadTime !== 'null') {
    sessionstorage.set('pageLoadTime', null)
    global.$rbl.push({
      event: 'intent_first_submit_time',
      content: {
        time: (new Date()).getTime() - pageLoadTime,
        intent: params.model_name || 'appointment',
        platform: 'PC',
        pageName: 'car-detail'
      }
    })
  }
}
/**
 *  intent 统一接口参数封装
 *  model_name:
 *  appointment:预约
 *  bargain:砍价
 *  consult：咨询
 *  discount_notify:降价通知
 *  time_limit_appointment： 预约看车好礼
 * @returns
 */
export const intentCommon = (params) => {
  intentFirstSubmitTimeGa(params)
  afterIntentSubmit(params)
  return post('/lurker/pc/intent/c2', { ...getParamsDefault(), ...params }, { isFormData: false, isAutoMsg: false })
}

/**
 *  intent 砍价
 * @returns
 */
export const intentBargain = (params) => {
  intentFirstSubmitTimeGa(params)
  afterIntentSubmit()
  return post('/lurker/pc/intent/bargain', { ...getParamsDefault(), ...params }, { isFormData: false, isAutoMsg: false })
}

/**
 *  预约看车-信息补充
 * @param {String} common_address mobile_number
 * @param {String} licensed_city 上牌城市
 * @param {String} expected_appointment_time
 * @param {String} is_optional_appointment_datetime
 * @param {String} is_local_index 0
 * @param {String} phone mobile_number
 * @param {String} sub_product
 * @param {String} car_id 车源ID
 * @param {String} need_installment 是否贷款
 * @param {String} price
 * @param {String} phone_source fr
 * @param {String} device pc
 * @param {String} submit_sources
 * @returns
 */
export const intentOrderEdit = (params) => {
  intentFirstSubmitTimeGa(params)
  afterIntentSubmit()
  return post('/lurker/pc/intent/appointment_edit', { ...getParamsDefault(), ...params }, { isFormData: false, isAutoMsg: false })
}

// 15s限时礼包弹窗 领取优惠券
export const campaignParticipate = params => get('https://api.renrenche.com/campaign/participate', params, { isFormData: false, isAutoMsg: false, isApiHost: false })
/**
 * @desc 获取省份下城市
 * @param {*} province: 省份
 * @returns
 */
export const getCityList = params => get('/lurker/city/getCityByProvince', params, { isAutoMsg: false })
