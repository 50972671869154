import React, { useState } from "react"
import Link from "next/link"
import PropTypes from "prop-types"
import styles from "./index.scss"

import CarItem from "../car-item/index.ssr"

const CarList = (props) => {
  const [sellBanner] = useState(
    props.saleItem.img ||
      "https://img1.rrcimg.com/dist/pc/images/search-list-sale-new1-efd2617a.png"
  )
  // 当前页面为第一页，在固定位置展示卖车卡片
  const showSellCard = mapIndex => mapIndex === (props.saleItem.index || 9) - 1 && props?.currentPage < 2
  const showEmpty = props?.carList?.length <= 0
  // 最后一页展示帮我找车
  return (
    <div
      className={`${styles["r-search-car-list"]} clearfix`}
      data-exposure="list_search_car_list"
    >
      {/* 车源列表数据 */}
      {!showEmpty &&
        props?.carList?.map((catItem, index) => {
          const jsxSell = (
            <Link href="/sell" key="sell">
              <a target="_blank">
                <div
                  key={catItem}
                  className="car-item-card sell"
                  onClick={() => {
                    global.$rbl.push("search_carlist-page1_pc-carlist-banner")
                  }}
                >
                  <img src={sellBanner} alt="卖车" />
                </div>
              </a>
            </Link>
          )
          const jsxItem = (
            <CarItem
              carObj={catItem}
              key={catItem.id + index}
              wrapperClass="car-item-card"
            />
          )
          return showSellCard(index) ? jsxSell : jsxItem
        })}
    </div>
  )
}

export default CarList

CarList.propTypes = {
  carList: PropTypes.array,
  currentPage: PropTypes.number,
  saleItem: PropTypes.object
}

CarList.defaultProps = {
  carList: [],
  currentPage: 1,
  saleItem: {}
}
