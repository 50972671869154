import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import './index.scss'
import { Modal } from 'antd'

const CreditSuccess = props => (
  <>
    {/* 提交成功展示 */}
    <Modal
      bodyStyle={{ padding: '0px' }}
      centered
      width={460}
      visible={props.visible}
      footer={null}
      closable
      onCancel={() => {
        props.onClose && props.onClose()
        global.$rbl.push('close')
      }}
    >
      <div className="r-credit-success">
        <div className="green-logo">
          <i className="iconfont icon-test_icon" />
        </div>
        <div className="confirm">
          <h2 className="confirm-title">提交成功</h2>
          <div className="confirm-message">人人车客服将尽快跟您电话核实，请保持手机畅通！</div>
        </div>
        <div className="flow-info"><div className="pic" /></div>
      </div>
    </Modal>
  </>
)

export default CreditSuccess

function mountAnywhere(Comp, root) {
  const div = document.createElement('div');
  (root || document.body).appendChild(div)

  // 向组件注入 onClose 方法，以便组件能调用关闭
  const Clone = React.cloneElement(Comp, {
    onClose: () => {
      ReactDOM.unmountComponentAtNode(div)
      div.parentNode.removeChild(div)
    }
  })
  ReactDOM.render(Clone, div)

  return div
}

export class UseCreditSuccess {
  constructor() {
    this.visible = false
    this.ref = null
  }

  open() {
    this.visible = true
    this.ref = mountAnywhere(
      <CreditSuccess visible={this.visible} />
    )
    return this.ref
  }

  close() {
    this.visible = false
  }
}

CreditSuccess.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func
}

CreditSuccess.defaultProps = {
  visible: false,
  onClose: () => ({})
}
