// localStorage方法封装
export default {
  /**
   * set 方法，设置
   * @param key String 键
   * @param value 值
   * @param expired 过期时间 ms,默认过期时间7天 7*24*60*60*1000=604800000ms
   */
  set(key, value, expired = 604800000) {
    if (!process.browser) {
      return false
    }
    if (!window.localStorage) {
      return false
    }
    const data = {
      value,
      writeTime: +new Date(), // 写入时间
      expired
    }
    // 值是数组，不能直接存储，需要转换 JSON.stringify
    localStorage.setItem(key, JSON.stringify(data))
    return true
  },

  /**
   * get 方法，获取
   * @param key 键
   */
  get(key) {
    if (!process.browser) {
      return false
    }
    if (!window.localStorage) {
      return false
    }
    const dataJSON = localStorage.getItem(key)

    // 当目标不存在时直接结束
    if (!dataJSON) {
      return false
    }
    let data = null
    try {
      data = JSON.parse(dataJSON)
      // 当数据的存在周期未定义时，它被认为是永久的
      if (!data.expired) {
        return data.value
      }
      // 数据声明期结束时释放数据
      if (this.isPass(data)) {
        this.del(key)
        return false
      }
      return data.value
    } catch (error) {
      this.del(key)
      return false
    }
  },

  /**
   * del 方法，删除
   * @param key 键
   */
  del(key) {
    if (!process.browser) {
      return false
    }
    if (!window.localStorage) {
      return false
    }
    localStorage.removeItem(key)
    return true
  },

  /**
   * isPass 方法，判断 value 值是否过期
   * @param value 值
   */
  isPass(value) {
    if (!value.value) {
      return true
    }
    const readTime = +new Date()
    return readTime - value.writeTime > value.expired
  },

  /**
   * clearAllPass 方法，清除过期的值
   */

  clearAllPass() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i) // 获取本地存储的Key
      this.get(key)
    }
  }
}
