import { $cookie, sessionstorage } from '$utils'

const POPWIN_SHOW_TIME = 'POPWIN_SHOW_TIME'// 弹层展示次数
const USER_VIEW_CAR_COUNT = 'USER_VIEW_CAR_COUNT'// 用户浏览数据
const SHOW_DETAIL_LAYER_MASK = 'SHOW_DETAIL_LAYER_MASK' // 在详情页种植会话SESSION 保证弹窗出现后其他逻辑的弹窗不出现

// 获取详情页存储数据
const getDetailData = (key) => {
  const detailData = sessionstorage.get('detailData')
  if (!key) { return detailData }
  return detailData[key]
}

const getPhoneSource = source => `${getDetailData('phoneSourcePrefix')}${source ? `_${source}` : ''}`

// 页面是否包含弹窗
const hasPopLayer = () => {
  const modals = document.getElementsByClassName('ant-modal-mask')
  return modals.length > 0
}

// 弹窗是否出现过
const hasPopLayerSubmit = () => !sessionstorage.get(SHOW_DETAIL_LAYER_MASK)

// 线索弹窗提交成功，保证其它逻辑弹窗不再出现
const popLayerSubmit = () => {
  sessionstorage.set(SHOW_DETAIL_LAYER_MASK, false)
}

// 用户浏览数据
const getUserviewJson = () => {
  const userViewCarsStr = localStorage.get(USER_VIEW_CAR_COUNT)
  let userViewCarList = {}
  if (userViewCarsStr) {
    userViewCarList = JSON.parse(userViewCarsStr)
  }
  return userViewCarList
}

// 获取车辆浏览次数
const getUserViewCarCount = (carId) => {
  const viewJson = getUserviewJson()
  const userViewCarCount = parseInt(viewJson[carId], 10) || 0
  return userViewCarCount
}

// 用户浏览次数+1
const updateUserViewCarCount = (carId) => {
  const viewJson = getUserviewJson()
  viewJson[carId] = getUserViewCarCount(carId) + 1
  localStorage.set(USER_VIEW_CAR_COUNT, JSON.stringify(viewJson))
}

// 弹层展示次数
const intentModalShowTimes = () => parseInt($cookie.get(POPWIN_SHOW_TIME), 10) || 0

// 线索弹层展示
const intentModalShow = () => { $cookie.set(POPWIN_SHOW_TIME, intentModalShowTimes() + 1) }


export default {
  hasPopLayer,
  getUserViewCarCount,
  updateUserViewCarCount,
  intentModalShowTimes,
  intentModalShow,
  getPhoneSource,
  hasPopLayerSubmit,
  popLayerSubmit,
  getDetailData
}
