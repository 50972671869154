import React, { useState, useEffect } from 'react' //
// import PropTypes from 'prop-types' useEffect
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Input, notification } from 'antd'
import iePic from '~/img/ie/isie.png'
import code from '~/img/ie/code.png'
import xcx from '~/img/ie/xcx.jpg'
import { checkCode } from './servies'
import style from './ietips.scss'

const IEBrowser = () => {
  const dispatch = useDispatch()
  const { ie } = useSelector(store => store)
  const router = useRouter()
  const [validCon, setValidCon] = useState({
    url: ''
  })
  const [validCode, setValidCode] = useState({
    code: '',
    msg: ''
  })
  const checkValidCode = async (val = 'aaaa') => {
    const { data } = await checkCode({
      value: val,
      suid: 'dwaxx21321321',
      action: 'rrcc-p-ie'
    })

    if (data.code_v2 !== '200') {
      setValidCon(data)
      setValidCode({ ...validCode, msg: '' })
      if (data.code_v2 === '429') {
        setValidCode({ ...validCode, msg: '尝试次数太多，请稍后' })
        return false
      }

      if (val !== 'aaaa') { setValidCode({ msg: '验证码错误！' }) }
      return false
    }

    return {}
  }

  const onContinue = async () => {
    // if (ie?.version <= 9) {
    //   dispatch({
    //     type: 'SET_IE',
    //     data: {
    //       ...ie,
    //       time: Date.now() + 600000
    //     }
    //   })
    //   return false
    // }
    if (!validCode.code.trim() || validCode.code.trim().length !== 4) {
      setValidCode({ ...validCode, msg: '输入错误！' })
      return false
    }
    const flag = await checkValidCode(validCode.code)
    if (flag) {
      dispatch({
        type: 'SET_IE',
        data: {
          ...ie,
          time: Date.now() + 600000
        }
      })
      if (!ie) {
        notification.open({
          message: '未检测到IE浏览器版本',
          description: '请切换浏览器访问'
        })
        return false
      }
      if (ie?.version <= 9) {
        router.push(router.asPath)
        return false
      }
      router.push(router.route, router.asPath)
    }
    return flag
  }

  const onChangeCode = (e) => {
    setValidCode({ code: e.target.value })
  }
  useEffect(() => {
    checkValidCode()
  }, [])


  return (
    // eslint-disable-next-line
    <section className={`${ie?.version <= 9 ? style.ietips9 : style.ietips}`}>
      <div className="inner">
        <div className="checkcode">
          <div className="checkcode-con">
            <div className="code-value">
              <Input
                className="inp"
                type="text"
                maxLength={4}
                placeholder="请输入"
                value={validCode.code}
                onChange={onChangeCode}
                allowClear
              />
              <div className="code-pic" onClick={() => { checkValidCode('aaaa') }}>
                {validCon.url ? <img src={validCon.url} alt="图形验证码" /> : <p>loading...</p>}
              </div>
            </div>
            {validCode.msg ? <p className="error">{validCode.msg}</p> : ''}
          </div>

          <Button type="primary" className="see-btn" onClick={onContinue}>继续访问</Button>
        </div>
        <div className="clearfix">
          <div className="error-pic">
            <img src={iePic} alt="错误图片" />
          </div>
          <div className="content">
            <h4 className="title">
              Hi！
            </h4>
            <p className="desc">
              您当前的浏览器版本过低，<br />
              可能存在安全风险！建议升级或更换浏览器
            </p>
            <ul className="download-browser clearfix">
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/intl/zh-CN/chrome/"><i className="browser-icon chrome" />谷歌 Chrome</a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://www.microsoft.com/zh-cn/edge"><i className="browser-icon edge" />IE Edga</a>
              </li>
            </ul>
            <div className="download-app">
              <h5>或直接扫码</h5>
              <ul className="download—pic clearfix">
                <li><img src={code} alt="二维码" /></li>
                <li><img src={xcx} alt="小程序" /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {
        ie?.version <= 9 ? <div className="ietips_mark" /> : ''
      }

    </section>
  )
}


export default IEBrowser
