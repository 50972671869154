import { get } from '$fetch'

/**
 * search keywords
 * @param {String} selected_cities 北京
 * @param {String} carid 车源id
 */
export const getSearchCityKeywords = params => get('/lurker/search/cityKeyword', params, { isLoading: false, isAutoMsg: false })

/**
 * search 车系检索
 * @param {String} type get_hot_info | get_info(words存在时)
 * @param {String} words
 */
export const getCarSearchInfo = params => get('/lurker/search/carSearch', params, { isLoading: false, isAutoMsg: false })
