/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import { Button, Input, message } from 'antd'
import { useSelector } from 'react-redux'
import SmsCodeVerify from '@/components/rc-sms-verify'
import ImgVerify from '@/components/rc-img-verify'
import {
  isCode, isPhone, $hooks, $cookie
} from '$utils'
import { UseCreditSuccess } from '../credit-success'
import { installmentIntent } from '../../service'
import { getsmscode } from '$service'

const CreditForm = (props) => {
  const { city } = useSelector(store => store)
  const [phone, setPhone] = useState(props?.phone || $cookie.get('rrc_login_phone') || '')
  const [isGettingSmsCode, setIsGettingSmsCode] = useState(false)// 是否正获取短信验证码
  const [code, setCode] = useState()
  const [errmsg, setErrmsg] = useState()
  const [verify, setVerify] = useState({
    imgVerify: '', // 返回的防刷验证码图形
    imgVerifyCode: null, // 防刷验证码
    imgVerifyCodeValid: false // 防刷验证码校验
  })

  // 图形验证码
  const [imgVerify, setImgVerify] = useState(false)
  const [imgVerifyCode, setImgVerifyCode] = useState(false)

  // 线索提交短信验证码
  const [smsVerify] = useState(false)
  const [smsVerifyCode, setSmsVerifyCode] = useState(true)


  // 获取短信验证码的倒计时
  const [seconds, setSeconds] = useState(59)
  const [id, start] = $hooks.useInterval(() => {
    if (seconds > 1) {
      setSeconds(seconds - 1)
    } else {
      clearInterval(id)
      setIsGettingSmsCode(false)
    }
  }, 1000)

  // 发送短信验证码按钮
  const sendSmsFuc = async () => {
    global.$rbl.push('发送验证码')
    // 校验
    if (!isPhone(phone)) {
      setPhone()
      setErrmsg('请输入正确的手机号')
    } else {
      try {
        await getsmscode({
          channel: 'bd_other',
          city: city.city,
          phone,
          code: verify.imgVerifyCode
        })
        setIsGettingSmsCode(true)
        setVerify({ ...verify, imgVerify: '', imgVerifyCode: '' })
        start()
      } catch (error) {
        console.log(error)
        if (error.status === 200) {
          const result = error.data
          if (result.status === 100) {
            setVerify({ ...verify, imgVerify: '', imgVerifyCode: '' })
          } else if (result.status === 400) {
            setVerify({ ...verify, imgVerify: result?.data?.image || result?.image })
          } else if (result.status === 5100) {
            message.error(error?.err_msg || '操作频繁，稍后再试')
          } else if (result.result === -4) {
            message.error('验证码错误')
          } else {
            message.error(result?.err_msg || result?.errMsg || '未知错误')
          }
        }
      }
    }
  }
  // 图形验证码填写完成隐藏该输入框,重置error
  useEffect(() => {
    if (verify.imgVerifyCode && isCode(verify.imgVerifyCode)) { setErrmsg(); sendSmsFuc() }
  }, [verify.imgVerifyCode])

  // 提交申请
  const installmentIntentFuc = async () => {
    global.$rbl.push('提交申请按钮')
    global.$rbl.push({
      event: 'SubmitClue_Loan_SMSCode_click',
      content: {
        expand: {
          submit_sources: props.submitSources
        }
      }
    })
    if (!isPhone(phone)) {
      setErrmsg('请输入正确的手机号')
      return
    }
    if (!isCode(code)) {
      setErrmsg('验证码格式有误')
      return
    }
    try {
      const params = {
        amount: '',
        car_id: props.carId || '',
        city: city.city_name || '全国',
        device: 'PC',
        flag: '',
        fr: city.fr,
        phone: Number(phone),
        month: '',
        phone_source: 'pc',
        product: '贷款',
        rrc_id: props.rrcId || '',
        source: '分期贷款',
        sub_product: '贷款',
        submit_sources: props.submitSources,
        vcode: code,
        code: imgVerifyCode || ''
      }
      if (props.formData) {
        Object.assign(params, props.formData)
      }
      await installmentIntent(params)
      props.onSuccess && props.onSuccess()
      global.$rbl.push({
        event: 'SubmitClue_Loan_Result_popup',
        content: {
          expand: {
            tag_value: '成功',
            submit_sources: props.submitSources
          }
        }
      })
      // 清楚倒计时
      clearInterval(id)
      setIsGettingSmsCode(false)
      setCode()
      setImgVerify()
      setImgVerifyCode()
      new UseCreditSuccess().open()
    } catch (error) {
      if (error.status === 200) {
        const result = error.data
        if (result.status === 100) {
          setImgVerify('')
        } else if (result.status === 400) {
          setImgVerify(result?.image || result?.data?.image)
        } else if (result.status === 5100) {
          message.error(error?.err_msg || '操作频繁，稍后再试')
        } else if (result.status === -4) {
          message.error('验证码错误')
        } else {
          message.error(result?.err_msg || result?.errMsg || '未知错误')
        }
      }
      global.$rbl.push({
        event: 'SubmitClue_Loan_Result_popup',
        content: {
          expand: {
            tag_value: '失败',
            submit_sources: props.submitSources
          }
        }
      })
      console.log(error)
    }
  }
  return (
    <>
      <div className="r-credit-form">
        {props?.title && <h3 className="title">{props?.title}</h3>}
        {props?.tag && <h3 className="tag">{props?.tag}</h3>}
        <Input
          onClick={() => { global.$rbl.push('输入手机号码') }}
          size="large"
          className="phone"
          placeholder="请输入手机号"
          maxLength={11}
          suffix={isGettingSmsCode ? (
            <span className="sending"> {seconds}秒后可重发</span>
          ) : (
            <span className="send" onClick={sendSmsFuc}>发送验证码</span>
          )}
          value={phone}
          onChange={(e) => {
            setErrmsg()
            setPhone(e.target.value)
          }}
        />

        {/* 防刷验证码 */}
        {verify.imgVerify ? (
          <div className="img-verify">
            <Input
              className="brush-code"
              size="large"
              placeholder="请输入验证码"
              bordered="false"
              maxLength={4}
              value={verify.imgVerifyCode}
              onChange={(e) => {
                setVerify({
                  ...verify,
                  imgVerifyCode: e.target.value,
                  imgVerifyCodeValid: isCode(e.target.value)
                })
              }}
            />
            <div className="brush-wrapper" onClick={sendSmsFuc}>
              <img
                className="brush-img"
                src={verify.imgVerify}
                alt="验证码图片"
              />
              <div className="brush-refresh" />
            </div>
          </div>
        ) : (
          <Input
            onClick={() => { global.$rbl.push('输入验证码') }}
            size="large"
            className="code"
            placeholder="请输入验证码"
            maxLength={4}
            value={code}
            onChange={(e) => { setErrmsg(); setCode(e.target.value) }}
          />
        )}
        {/* 防刷图形验证码 */}
        <ImgVerify
          className="image-verify"
          imgVerify={imgVerify}
          onImgVerifyCode={(c) => { setImgVerifyCode(c) }}
          onRefresh={() => { installmentIntentFuc(true) }}
        />
        {/* 防刷短信验证码 */}
        <SmsCodeVerify
          autoSend
          phone={phone}
          smsVerify={smsVerify}
          onErrMsg={((err) => { setErrmsg(err) })}
          onSmsCode={(c) => { setSmsVerifyCode(c) }}
        />
        {errmsg && <p className="error-msg">{errmsg}</p> }
        <Button className="submit-btn" block type="primary" onClick={installmentIntentFuc}>
          {props?.btnTxt || '提交申请'}
        </Button>
      </div>
    </>
  )
}
export default CreditForm

CreditForm.propTypes = {
  phone: PropTypes.string,
  submitSources: PropTypes.string,
  carId: PropTypes.string,
  rrcId: PropTypes.string,
  formData: PropTypes.object,
  onSuccess: PropTypes.func,
  title: PropTypes.string,
  tag: PropTypes.string,
  btnTxt: PropTypes.string
}

CreditForm.defaultProps = {
  phone: '',
  submitSources: '',
  carId: '',
  rrcId: '',
  formData: {},
  onSuccess: () => ({}),
  title: '',
  tag: '',
  btnTxt: ''
}
