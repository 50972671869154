/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from "react"
import {
 Breadcrumb, AutoComplete, Input, Icon 
} from "antd"
// import { useRouter } from "next/router"
import PropTypes from "prop-types"
import NoSSR from "@/components/rc-no-ssr"
import { debounce } from "$utils"
import { getCarSearchInfo } from "./service"
import "./style.scss"

const { Option, OptGroup } = AutoComplete

const RBreadCrumb = ({ breadOption, searchGa, clickGa }) => {
  const {
 bPinyin, brand, sPinyin, series, cityName, title 
} = breadOption
  let setKeywordInterval = useRef()
  const [breadCrumbs, setBreadCrumbs] = useState([])
  const initLink = () => {
    const getCityName = () => (cityName === "全国" ? "" : cityName)
    const links = [
      {
        name: `${getCityName()}二手车`,
        link: `/buy`
      }
    ]
    if (bPinyin && sPinyin) {
      links.push({
        name: `${getCityName()}二手车出售`,
        link: `/buy`
      })
      links.push({
        name: `${getCityName()}二手${brand}`,
        link: `/buy?brand=${brand}`
      })
      links.push({
        name: `${getCityName()}二手${series}`,
        link: `/buy?brand=${brand}&car_series=${series}`
      })
      links.push({ name: `${title}`, link: "" })
    } else if (brand && series && title) {
      links.push({ name: `${getCityName()}二手${brand}`, link: "" })
      links.push({ name: `${getCityName()}二手${series}`, link: "" })
      links.push({ name: `${title}`, link: "" })
    } else if (brand && series) {
      links.push({ name: `${getCityName()}二手${brand}`, link: "" })
      links.push({ name: `${getCityName()}二手${series}`, link: "" })
    } else if (brand) {
      links.push({ name: `${getCityName()}二手车出售`, link: "" })
      links.push({ name: `${getCityName()}二手${brand}`, link: "" })
    } else if (series) {
      links.push({ name: `${getCityName()}二手车出售`, link: "" })
      links.push({ name: `${getCityName()}二手${series}`, link: "" })
    } else {
      links.push({ name: `${getCityName()}二手车出售`, link: "" })
    }
    return links
  }
  useEffect(() => {
    setBreadCrumbs(initLink())
  }, [breadOption])

  // 获取历史搜索记录
  const getHistoryRecord = () => {
    try {
      const record = localStorage.getItem("rrc_search_record")
      if (record && record.length) {
        return JSON.parse(record || "[]")
      }
      return []
    } catch (error) {
      return []
    }
  }
  // 移除历史搜索记录
  const removeHistoryRecord = (record) => {
    if (record) {
      let historyRecord = getHistoryRecord()
      if (historyRecord instanceof Array) {
        historyRecord = historyRecord.reduce((p, c) => {
          if (c.val !== record) {
            p.push(c)
          }
          return p
        }, [])
        localStorage.setItem(
          "rrc_search_record",
          JSON.stringify(historyRecord)
        )
      }
    } else {
      localStorage.removeItem("rrc_search_record")
    }
  }
  // 设置历史搜索记录
  const setHistoryRecord = (newrecord) => {
    // 保存历史记录
    let isSearchWordsExit = false
    let historyRecord = getHistoryRecord()
    if (historyRecord instanceof Array) {
      historyRecord = historyRecord.reduce((p, c) => {
        if (c.val === newrecord) {
          isSearchWordsExit = true
          c.timeStr = new Date().getMilliseconds()
        }
        p.push(c)
        return p
      }, [])
      if (!isSearchWordsExit) {
        historyRecord.push({
          val: newrecord,
          timeStr: new Date().getMilliseconds()
        })
      }
      localStorage.setItem("rrc_search_record", JSON.stringify(historyRecord))
    }
  }

  // 城市检索关键字---start
  const [cityKeywords, setCityKeywords] = useState([])

  // placeholder 关键字---start
  const [keyword, setKeyword] = useState()
  const setPlaceholder = () => {
    let keywordIndex = Number(localStorage.getItem("rrc_keyword_index")) || 0
    if (keywordIndex < cityKeywords.length - 1) {
      keywordIndex += 1
    } else {
      keywordIndex = 0
    }
    setKeyword(cityKeywords[keywordIndex])
    localStorage.setItem("rrc_keyword_index", keywordIndex)
  }
  useEffect(() => {
    // 10s 更换 placeholder 关键字
    if (cityKeywords.length > 0) {
      setPlaceholder()
      setKeywordInterval = setInterval(() => {
        setPlaceholder()
      }, 10000)
    }
    return () => {
      clearInterval(setKeywordInterval.current)
    }
  }, [cityKeywords.length])
  // placeholder 关键字---end

  // 列表自动补充数据 dataSource
  const [dataSource, setDataSource] = useState()

  // 获取列表自动补充数据 input 值
  const [words, setWords] = useState()
  const getSearchInfo = async () => {
    const historyRecord = getHistoryRecord()
    const historyTitleJsx = (
      <div className="history-label clearfix">
        <i className="iconfont icon-lishi" />
        历史搜索
        <span
          className="remove-all-icon"
          onClick={(e) => {
            e.stopPropagation()
            removeHistoryRecord()
            getSearchInfo()
          }}
        >
          <i className="iconfont  icon-shanchu" />
        </span>
      </div>
    )
    const historyRecordJsx = (
      <OptGroup key="searchHistory" label={historyTitleJsx} className="history">
        {historyRecord.map(opt => (
          <Option
            key={opt.val}
            value={opt.val}
            className="history-option clearfix"
          >
            <span>{opt.val}</span>
            <span
              className="remove-icon"
              onClick={(e) => {
                e.stopPropagation()
                removeHistoryRecord(opt.val)
                getSearchInfo()
              }}
            >
              <Icon type="close-circle" theme="filled" />
            </span>
          </Option>
        ))}
      </OptGroup>
    )
    const type = words ? "get_info" : "get_hot_info"
    try {
      const result = await getCarSearchInfo({ type, words })
      if (!cityKeywords || !cityKeywords.length) {
        const _words = []
        result?.data?.city_keywords?.map((item) => {
          _words.push(item.keyword)
          return null
        })
        setCityKeywords(_words)
      }
      const hotTitleJsx = (
        <div className="hot-label clearfix">
          <i className="iconfont icon-redu" />
          热门搜索
        </div>
      )
      const hotRecordJsx = (
        <OptGroup key="searchHot" label={hotTitleJsx} className="hot">
          {result.data.search.map(opt => (
            <Option key={opt} value={opt}>
              {opt}
            </Option>
          ))}
        </OptGroup>
      )
      setDataSource(
        words ? result.data.search : [historyRecordJsx, hotRecordJsx]
      )
    } catch (error) {
      setDataSource([])
    }
  }
  useEffect(() => {
    debounce(getSearchInfo(), 300)
  }, [words])

  // 搜索按钮：根据关键字跳转买车页面
  const goCarListPage = (optionKeyword) => {
    const searchWords = optionKeyword || words || keyword
    setHistoryRecord(searchWords)
    // const router = useRouter()
    // 页面跳转
    // router?.push({ pathname: "/buy", query: { wd: searchWords } })
    window.location.href = `/buy?wd=${encodeURIComponent(`${searchWords}`)}` 
  }

  return (
    <>
      <div className="r-bread-crumb clearfix">
        <Breadcrumb separator=">" className="r-bread-crumb__crumbs">
          {breadCrumbs.length &&
            breadCrumbs.map((c, index) => (
              <Breadcrumb.Item key={index} href={c.link}>
                {c.name}
              </Breadcrumb.Item>
            ))}
        </Breadcrumb>
        <NoSSR>
          <div className="r-bread-crumb__search">
            <AutoComplete
              dropdownClassName="search-dropdown"
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 263 }}
              size="large"
              style={{ width: "100%" }}
              placeholder={keyword}
              optionLabelProp="value"
              dataSource={dataSource}
              onChange={(v) => {
                setWords(v)
              }}
              onSelect={goCarListPage}
              onFocus={getSearchInfo}
              value={words}
            >
              <Input
                className="search"
                data-click={clickGa || 'car-detail_search_input_click'}
                suffix={(
                  <Icon
                    type="search"
                    className="certain-category-icon"
                    onClick={(e) => {
                      e.stopPropagation()
                      global.$rbl.push(searchGa || "car-detail_search_button_click")
                      goCarListPage()
                    }}
                  />
                )}
              />
            </AutoComplete>
          </div>
        </NoSSR>
      </div>
    </>
  )
}

export default RBreadCrumb

RBreadCrumb.propTypes = {
  breadOption: PropTypes.object,
  searchGa: PropTypes.string,
  clickGa: PropTypes.string
  // router: PropTypes.object
}

RBreadCrumb.defaultProps = {
  breadOption: {},
  searchGa: '',
  clickGa: ''
  // router: {}
}
