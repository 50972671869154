/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Checkbox, Input, message, Modal
} from 'antd'
import classnames from 'classnames'
import SmsCodeVerify from '@/components/rc-sms-verify'
import ImgVerify from '@/components/rc-img-verify'
import { getsmscode } from '$service'
import {
  $hooks, isPhone, isCode, isNumberInput, $cookie, sessionstorage, intentFirstSubmitTimeGa
} from '$utils'
import styles from './index.scss'

const RcSmsPhone = (props) => {
  const [showSmsBtn, setSmsBtnVisible] = useState(!props?.sendSmsOnSubmit)
  const [phone, setPhone] = useState($cookie.get('rrc_login_phone') || sessionstorage.get('intentPhone') || '') // 手机号
  const [verifyCode, setVerifyCode] = useState() // 短信验证码
  const [isGettingSmsCode, setGettingSmsCode] = useState() // 是否正获取短信验证码

  // 图形验证码
  const [imgVerify, setImgVerify] = useState()
  const [imgVerifyCode, setImgVerifyCode] = useState()

  // 短信验证码
  const [smsVerify, setSmsVerify] = useState()
  const [smsVerifyCode, setSmsVerifyCode] = useState()

  const [errMsg, setErrMsg] = useState() // 是否正获取短信验证码
  const [noteChecked, setNoteChecked] = useState(props.noteChecked) // 勾选协议
  const [noteModalVisible, setNoteModalVisible] = useState(false) // 勾选协议

  // 登录按钮点击状态
  const [canSubmitClick, setCanSubmitClick] = useState(false)
  useEffect(() => {
    let flag = isPhone(phone) && isCode(verifyCode) && noteChecked
    if (props.noSms) {
      flag = isPhone(phone) && noteChecked
    }
    setCanSubmitClick(flag)
  }, [phone, verifyCode, noteChecked, props.noSms])

  // 手机号填充
  useEffect(() => {
   if(props.phone !== phone){
    setPhone(props.phone)
   }
  }, [props.phone])

  // onPhoneChange
  useEffect(() => {
    if(props.phone !== phone){
      props.onPhoneChange && props.onPhoneChange(phone)
    }
  }, [phone])

  // 获取短信验证码的倒计时
  const [seconds, setSeconds] = useState(59)
  const [id, start] = $hooks.useInterval(() => {
    if (seconds > 1) {
      setSeconds(seconds - 1)
    } else {
      clearInterval(id)
      setGettingSmsCode(false)
    }
  }, 1000)

  // 开始倒计时60s
  const startInterval = () => {
    setSeconds(59)
    start()
    setGettingSmsCode(true)
  }

  // 停止倒计时
  const stopInterval = () => {
    clearInterval(id)
    setGettingSmsCode(false)
  }

  // 获取验证码按钮传入的默认方法
  const getSmsCodeFuncDefault = async (isrefresh) => {
    const _isrefresh = isrefresh && isrefresh === true
    if (_isrefresh) {
      // 组件状态
      setImgVerifyCode()
    }
    // 协议勾选校验
    if (!_isrefresh && !noteChecked) {
      if (props.noteModal) {
        setNoteModalVisible(true)
        global.$rbl.push('login-page_privacy_popup')
      } else {
        setErrMsg('请勾选用户协议')
      }
      return
    }

    // 手机号校验
    if (!isPhone(phone)) {
      setErrMsg('手机号输入有误')
      return
    }
    // 校验图形验证码
    if (!_isrefresh && imgVerify && !isCode(imgVerifyCode)) {
      setErrMsg('验证码输入有误')
      return
    }
    setErrMsg('')
    try {
      const params = {
        phone,
        city: 'cn'
      }
      if (!_isrefresh) {
        params.code = imgVerifyCode
      }
      await getsmscode(params)
      startInterval() // 倒计时60s
      // 组件状态
      setImgVerify()
      setImgVerifyCode()
      // 展示倒计时的按钮
      setSmsBtnVisible(true)
    } catch (error) {
      stopInterval()
      if (error.status === 200) {
        const result = error.data
        const imgsrc = result?.image || result?.data?.image
        if (result.status === 100) {
          setImgVerify('')
          setErrMsg('')
        } else if (result.status === 400) {
          setImgVerify(imgsrc)
          setImgVerifyCode()
        } else if (result.status === 1000) {
          // 短信验证码
          setSmsVerify(true)
        } else if (result.status === 1001) {
          // 短信验证码错误
          setErrMsg('短信验证码输入有误')
        } else {
          message.error(result?.err_msg || result?.errMsg || '未知错误')
        }
      }
    }
  }
  const getSmsCodeFuncDefaultSync = $hooks.useSyncCallback(
    getSmsCodeFuncDefault
  )

  // 提交事件
  const handleSubmit = async () => {
    // 提交时才获取短信验证码时
    if (props.sendSmsOnSubmit && !showSmsBtn) {
      getSmsCodeFuncDefault()
      return
    }
    // 校验
    if (!isPhone(phone)) {
      setErrMsg('手机号输入有误')
      return
    }
    if (!props.noSms && !isCode(verifyCode)) {
      setErrMsg('短信验证码输入有误')
      return
    }

    try {
      // 第一次线索提交埋点上报
      if(props.intentFirstSubmitTimeGaTxt){
        intentFirstSubmitTimeGa(props.intentFirstSubmitTimeGaTxt, props.submitSources)
      }
      sessionstorage.set('intentPhone', phone)
      // 图形验证码code, 短信验证码vcode
      const params = {
        mobile_number: phone,
        submit_sources: props.submitSources,
        code: imgVerifyCode,
        vcode: verifyCode || smsVerifyCode // 短信验证码、防刷的短信验证码
      }
      // 传入的参数
      if (props.submitParams) {
        Object.assign(params, props.submitParams)
      }
      props.submitFunc && await props.submitFunc(params)
      props.onSuccess && props.onSuccess(phone)
      // 成功埋点
      global.$rbl.push('提交成功')
    } catch (error) {
      global.$rbl.push('提交失败')
      if (error.status === 200) {
        const result = error.data
        const imgsrc = result?.image || result?.data?.image
        if (result.status === 100) {
          setImgVerify('')
          setErrMsg('')
        } else if (result.status === 400) {
          setImgVerify(imgsrc)
          setImgVerifyCode()
        } else if (result.status === 1000) {
          // 短信验证码
          setSmsVerify(true)
        } else if (result.status === 1001) {
          // 短信验证码错误
          setErrMsg('短信验证码输入有误')
        } else {
          message.error(result?.err_msg || result?.errMsg || '未知错误')
        }
      }
    }
  }

  return (
    <div className={classnames(styles['r-phone'], props.wrapClassName)}>
      <div className="row">
        <Input
          data-click={props.phoneClickGa}
          className="phone"
          placeholder="请输入手机号码"
          value={phone}
          onInput={(e) => {
            if (isNumberInput(e.target.value)) {
              setPhone(e.target.value)
            }
          }}
          maxLength={11}
        />
      </div>

      {/* 防刷图形验证码 */}
      <ImgVerify
        wrapperClassName="row"
        imgVerify={imgVerify}
        imgVerifyCode={imgVerifyCode}
        onImgVerifyCode={(code) => {
          setImgVerifyCode(code)
        }}
        onRefresh={() => {
          getSmsCodeFuncDefault(true)
        }}
      />

      {/* 短信验证码 */}
      {!props.noSms && showSmsBtn && (
        <div className="row">
          <Input
            size="large"
            placeholder="请输入验证码"
            bordered="false"
            maxLength={4}
            value={verifyCode}
            onInput={(e) => {
              if (isNumberInput(e.target.value)) {
                setVerifyCode(e.target.value)
              }
            }}
            onKeyDown={(e) => {
              if (e.keyCode !== 13) { return }
              handleSubmit()
            }}
          />
          {isGettingSmsCode ? (
            <Button
              className="getsmscode-btn getsmscode-btn--disabled"
              disabled
            >
              <span className="seconds">{seconds}</span>秒后可重发
            </Button>
          ) : (
            <Button
              className="getsmscode-btn getsmscode-btn--default"
              onClick={() => {
                getSmsCodeFuncDefault()
                global.$rbl.push('login-page_verification-code_click')
              }}
            >
              获取验证码
            </Button>
          )}
        </div>
      )}

      {/* 防刷短信验证码 */}
      <SmsCodeVerify
        autoSend
        wrapperClassName="row"
        phone={phone}
        smsVerify={smsVerify}
        onErrMsg={(err) => {
          setErrMsg(err)
        }}
        onSmsCode={(code) => {
          setSmsVerifyCode(code)
        }}
      />

      {/* 错误消息 */}
      {errMsg && <p className="err-msg">{errMsg}</p>}

      <Button
        data-click={props.submitBtnGa}
        disabled={!canSubmitClick}
        className={classnames('submit-btn', {
          disabled: !canSubmitClick
        })}
        block
        type="primary"
        onClick={handleSubmit}
      >
        {props.submitBtnTxt}
      </Button>
      {props.noteJsx && (
        <div className="tip clearfix">
          <Checkbox
            className="check"
            checked={noteChecked}
            onChange={(e) => {
              global.$rbl.push({
                event: 'login-page_privacy-check_click',
                content: {
                  expand: {
                    agreement: e.target.checked ? 'check' : 'uncheck'
                  }
                }
              })
              setNoteChecked(e.target.checked)
            }}
          />
          {props.noteJsx}
        </div>
      )}

      <Modal
        wrapClassName="phone-note-modal"
        closable={false}
        width={400}
        bodyStyle={{ padding: '40px 30px', borderRadius: '8px' }}
        footer={null}
        centered
        visible={noteModalVisible}
        maskClosable={false}
      >
        <h1 className="tip">温馨提示</h1>
        <div className="note">{props.noteJsx}</div>
        <div className="btns">
          <Button
            className="btn"
            onClick={() => {
              setNoteChecked(false)
              setNoteModalVisible(false)
              global.$rbl.push({
                event: 'login-page_privacy_click',
                content: {
                  expand: {
                    value: 'disagree'
                  }
                }
              })
            }}
          >
            不同意
          </Button>
          <Button
            className="btn"
            onClick={() => {
              setNoteChecked(true)
              setNoteModalVisible(false)
              getSmsCodeFuncDefaultSync()
              global.$rbl.push({
                event: 'login-page_privacy_click',
                content: {
                  expand: {
                    value: 'agree'
                  }
                }
              })
            }}
          >
            同意
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default RcSmsPhone

RcSmsPhone.propTypes = {
  children: PropTypes.any,
  noSms: PropTypes.bool, // true: 不需要短信验证码
  sendSmsOnSubmit: PropTypes.bool, // true:提交时发送短信验证码并显示倒计时，同时展示短信验证码输入框；false:在手机号输入框展示获取短信验证码按钮
  submitBtnTxt: PropTypes.string, // 提交文案
  submitSources: PropTypes.string, // submit_sources
  submitParams: PropTypes.object, // submit_sources
  submitFunc: PropTypes.func, // 提交文案
  onSuccess: PropTypes.func, // 提交成功的回调
  noteJsx: PropTypes.element, // 协议内容
  noteModal: PropTypes.bool, // 协议未勾选展示弹窗
  noteChecked: PropTypes.bool, // 协议是否默认勾选
  wrapClassName: PropTypes.string, // 自定义样式
  phone: PropTypes.string, // 携带的手机号
  phoneClickGa: PropTypes.string, // 手机号输入框点击埋点
  submitBtnGa: PropTypes.string, // 提交按钮点击埋点
  intentFirstSubmitTimeGaTxt: PropTypes.string, // 第一次提交的intent
  onPhoneChange: PropTypes.func // 第一次提交的intent
}

RcSmsPhone.defaultProps = {
  children: null,
  noSms: false,
  sendSmsOnSubmit: false,
  submitBtnTxt: '提交',
  submitSources: '',
  submitParams: {},
  submitFunc: () => ({}),
  onSuccess: () => ({}),
  noteJsx: <></>,
  noteModal: false,
  noteChecked: true,
  wrapClassName: '',
  phone: '',
  phoneClickGa: '',
  submitBtnGa: '',
  intentFirstSubmitTimeGaTxt: '',
  onPhoneChange: () => ({})
}


