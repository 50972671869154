import { post, get } from '$fetch'
import { jsonp } from '$utils'

/**
 * @desc 获取短信验证码
 * @param {*} phone: 手机号
 * @param {*} city: 城市
 * @param {*} channel: fr
 * @param {*} __jwtsignature: jwtsignature
 * @returns
 */
export const getsmscode = data => post('/lurker/artemis/verifycode', {
  ...data, typ: '1', os: 'pc'
}, { isApiHost: true, isFormData: false, isAutoMsg: false })

/**
 * @desc 获取百度地图推荐地址
 * @param {*} phone: 手机号
 * @param {*} city: 城市
 * @param {*} channel: fr
 * @param {*} __jwtsignature: jwtsignature
 * @returns
 */
export const getSuccessAddress = () => get('', {}, { isAutoMsg: false })

// 百度appkey
export const AK = 'zp9sMMk9yrBGoBsXMKTRcFDDYjSRWHys'

// 获得联想词条
export const getSuggestionList = ({ query, region }) => {
  const url = `//api.map.baidu.com/place/v2/suggestion?city_limit=true&query=${query}&ak=${AK}&output=json&region=${region}`
  return jsonp(url)
}

// 根据地区和名称获得地点列表
export const getBMList = ({ query, region }) => {
  const url = `//api.map.baidu.com/place/v2/search?query=${query}&ak=${AK}&output=json&region=${region}`
  return jsonp(url)
}

// 逆地理编码
export const getAddressByLocation = ({ lat, lng }) => {
  const url = `//api.map.baidu.com/geocoder/v2/?location=${lat},${lng}&output=json&pois=1&ak=${AK}`
  return jsonp(url)
}

// 地理编码
export const getLocationByAddress = ({ address, region }) => {
  const url = `//api.map.baidu.com/geocoder/v2/?address=${address}&output=json&city=${region}&ak=${AK}`
  return jsonp(url)
}

// 品牌车系
export const getAllBrands = params => get('/lurker/v1/car/get_brand?type=pc', { ...params }, { isAutoMsg: false, isLoading: false })


/**
 * @desc 全局数据、cookie获取
 * @param {*} city: url中的城市abbr
 * @param {*} fr: url中的fr
 * @returns
 */

export const getPublicVariables = params => get('/lurker/api/user/common_public_variables', params, {
  isLoading: false,
  isAutoMsg: false
})


/**
 * @desc Header 城市列表信息
 * @param {*} city: 城市abbr,默认‘cn’
 * @param {*} province: 城市名称,默认‘全国’
 * @returns
 */
export const getNavigationCityInfo = params => get('/lurker/city/navigationCityInfo', params, {
  isLoading: false,
  isAutoMsg: false
})
