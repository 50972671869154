import { get, post } from "$fetch"


/**
 * 列表首页
 */
export const getSearchMain = params => get("/lurker/search/pc_home/main", params, { isAutoMsg: false })

/**
 * @desc 筛选器选项
 * @returns
 */
export const getFilterOptions = data => get(
    "/lurker/search/pc_filter/conf",
    { ...data, os: "pc" },
    { isAutoMsg: false }
  )

/**
 * @desc 获取车源列表
 * @returns 【】
 */
export const getCarList = params => post("/lurker/search/pc_select", params, { abortConfig: true })

/**
 * 上新提醒我
 */
export const subscriptionAdd = params => post("/lurker/artemis/subscriptions/add", params, { isFormData: true, abortConfig: true })

/**
 * 帮我找车
 */
export const bangmaiBuyer = async params => post("/lurker/v1/intent/c2/buyer", { ...params }, { abortConfig: true, isAutoMsg: false })


/**
 * 卖车页展位图
 */
export const getListSellBanner = async () => get("/lurker/search/pc_home/conf")
