const provinceConfig = [
  { value: '澳门', label: '澳门', isLeaf: false },
  { value: '安徽', label: '安徽', isLeaf: false },
  { value: '北京', label: '北京', isLeaf: false },
  { value: '重庆', label: '重庆', isLeaf: false },
  { value: '福建', label: '福建', isLeaf: false },
  { value: '甘肃', label: '甘肃', isLeaf: false },
  { value: '贵州', label: '贵州', isLeaf: false },
  { value: '广东', label: '广东', isLeaf: false },
  { value: '广西', label: '广西', isLeaf: false },
  { value: '海南', label: '海南', isLeaf: false },
  { value: '河北', label: '河北', isLeaf: false },
  { value: '河南', label: '河南', isLeaf: false },
  { value: '湖北', label: '湖北', isLeaf: false },
  { value: '湖南', label: '湖南', isLeaf: false },
  { value: '黑龙江', label: '黑龙江', isLeaf: false },
  { value: '吉林', label: '吉林', isLeaf: false },
  { value: '江苏', label: '江苏', isLeaf: false },
  { value: '江西', label: '江西', isLeaf: false },
  { value: '辽宁', label: '辽宁', isLeaf: false },
  { value: '宁夏', label: '宁夏', isLeaf: false },
  { value: '内蒙古', label: '内蒙古', isLeaf: false },
  { value: '青海', label: '青海', isLeaf: false },
  { value: '上海', label: '上海', isLeaf: false },
  { value: '四川', label: '四川', isLeaf: false },
  { value: '山东', label: '山东', isLeaf: false },
  { value: '山西', label: '山西', isLeaf: false },
  { value: '陕西', label: '陕西', isLeaf: false },
  { value: '天津', label: '天津', isLeaf: false },
  { value: '新疆', label: '新疆', isLeaf: false },
  { value: '西藏', label: '西藏', isLeaf: false },
  { value: '香港', label: '香港', isLeaf: false },
  { value: '浙江', label: '浙江', isLeaf: false }
]

export default provinceConfig
