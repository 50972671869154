import { get } from '$fetch'

/**
 * @desc 全局数据、cookie获取
 * @param {*} city: url中的城市abbr
 * @param {*} fr: url中的fr
 * @returns
 */

export const getPublicVariables = params => get('/lurker/api/user/common_public_variables', params, {
  isLoading: false,
  isAutoMsg: false
})


/**
 * @desc Header 城市列表信息
 * @param {*} city: 城市abbr,默认‘cn’
 * @param {*} province: 城市名称,默认‘全国’
 * @returns
 */
export const getNavigationCityInfo = params => get('/lurker/city/navigationCityInfo', params, {
  isLoading: false,
  isAutoMsg: false
})
