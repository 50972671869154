import React, { useState } from "react"
import ReactDOM from "react-dom"
import {
 Modal, Input, Button, Checkbox 
} from "antd"
import PropTypes from "prop-types"
import LazyLoad from "react-lazyload"
import SmsCodeVerify from "@/components/rc-sms-verify"
import ImgVerify from "@/components/rc-img-verify"
import {
  $cookie,
  isPhone,
  isCode,
  sessionstorage,
  getSuccessMsg
} from "$utils"
import { intentCommon } from "../service"
import { UseIntentOrderExt } from "../order-ext"
import { UseModalSuccess } from "../success"
import styles from "./styles.scss"
import $dom from "../../../util"
import imgBoy from "~/img/buy/katong-boy.png"

// 预约看车弹窗
const IntentOrder = (props) => {
  const {
 onClose, intentType, submitParams, otherParams, isPopWin 
} = props
  const gaPageName = () => {
    const { pathname } = window.location

    if (/^\/buy\/detail/.test(pathname)) {
      return "car_detail"
    }
    if (/^\/buy/.test(pathname)) {
      return "search"
    }
    return window.location.pathname
  }
  const { basicInfo, ino } = otherParams
  const [visible, setVisible] = useState(props.visible)
  const [phone, setPhone] = useState(
    $cookie.get("rrc_login_phone") || sessionstorage.get("intentPhone") || ""
  )
  const [errMsg, setErrMsg] = useState()
  const [noteChecked, setNoteChecked] = useState(true)
  const isPopWinIntent = isPopWin && isPopWin === true

  // 图形验证码
  const [imgVerify, setImgVerify] = useState(false)
  const [imgVerifyCode, setImgVerifyCode] = useState(false)

  // 短信验证码
  const [smsVerify, setSmsVerify] = useState(false)
  const [smsVerifyCode, setSmsVerifyCode] = useState(true)

  const mainTitle = intentType === "order" ? "您预约的车辆" : "您咨询的车辆"
  const closeModal = () => {
    global.$rbl.push(`${gaPageName()}_关闭`)
    setVisible(false)
    onClose && onClose()
  }
  // 预约看车线索提交
  const orderSubmit = async (isrefresh) => {
    const _isrefresh = isrefresh && isrefresh === true
    if (!_isrefresh) {
      global.$rbl.push(submitParams.submit_sources)
      global.$rbl.push(`${gaPageName()}_submit`)
    }
    setErrMsg("")
    if (!isPhone(phone)) {
      setErrMsg("手机号输入有误")
      return
    }
    if (!_isrefresh && imgVerify && imgVerifyCode && !isCode(imgVerifyCode)) {
      setErrMsg("图形验证码输入有误")
      return
    }
    if (smsVerify === true && (!smsVerifyCode || !isCode(smsVerifyCode))) {
      setErrMsg("短信验证码输入有误")
      return
    }

    try {
      const params = {
        model_name: intentType === "order" ? "appointment" : "consult",
        ...submitParams,
        mobile_number: phone
      }
      // 图形验证码参数
      if (imgVerify && imgVerifyCode && isCode(imgVerifyCode)) {
        params.verify_code = imgVerifyCode
      }
      // 短信验证码参数
      if (smsVerify && smsVerifyCode && isCode(smsVerifyCode)) {
        params.verification_code = smsVerifyCode
      }
      const result = await intentCommon(params)
      $dom.popLayerSubmit()
      setImgVerify("")
      if (result.data.display_next_page === true) {
        const nextSubmitParams = {
          ...submitParams,
          phone,
          sub_product: "appointment",
          model_name: intentType === "order" ? "appointment" : "consult"
        }
        new UseIntentOrderExt({
          isSmsVerify: smsVerify,
          modelName: intentType === "order" ? "appointment" : "consult",
          submitParams: nextSubmitParams,
          otherParams,
          profile: result.data.profile
        }).open()
        closeModal()
      } else {
        const showSuccess = () => {
          new UseModalSuccess({ msg: getSuccessMsg(intentType), ino }).open()
        }
        // 未登录提交信息补充后，应该弹出登录弹窗，并发送短信验证码(触发短信验证码不登录)
        if (
          !smsVerify &&
          !$cookie.get("rrc_login_phone") &&
          !$cookie.get("rrc_login_token")
        ) {
          sessionstorage.set("loginBackModalInfo", {
            msg: getSuccessMsg(intentType),
            ino
          })
          window.location.href = `${
            window.location.origin
          }/login?source=${encodeURIComponent(
            window.location.href
          )}&phone=${phone}`
          // new UseLogin({
          //   isAutoMsg: false,
          //   isAutoLogin: true,
          //   phone,
          //   onSuccessCallBack: () => {
          //     showSuccess()
          //   }
          // }).open()
        } else {
          showSuccess()
        }
        closeModal()
      }
      global.$rbl.push(`${submitParams.submit_sources}-提交成功`)
    } catch (error) {
      if (error.status === 200) {
        const result = error.data
        setImgVerify("")
        setErrMsg("")
        if (result.status === 400) {
          // 图形验证码
          global.$rbl.push({
            event: "m_verification_show",
            content: { param: "verification" }
          })
          setImgVerify(result.image)
        } else if (result.status === 1000) {
          // 短信验证码
          setSmsVerify(true)
        } else if (result.status === 1001) {
          // 短信验证码
          setErrMsg("短信验证码输入有误")
        }
      }
    }
  }
  return (
    <Modal
      bodyStyle={{ padding: "0px" }}
      style={{ minWidth: "520px" }}
      footer={null}
      centered
      visible={visible}
      onCancel={() => {
        closeModal()
      }}
    >
      <div className={styles.intentModal}>
        {isPopWinIntent ? (
          <>
            <div className="pop-win-intent clearfix">
              <div className="pop-logo">
                <LazyLoad>
                  <img src={imgBoy} alt="喜欢这辆车？" />
                </LazyLoad>
              </div>
              <div className="pop-info">
                <h2 className="pop-title">喜欢这款车？</h2>
                <div className="pop-sub">立即约车主见面看车！</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="logo">
              <i className="iconfont icon-shouji" />
            </div>
            <h2 className="title">{mainTitle}</h2>
            <p className="car-title">
              <i />
              {basicInfo.title}
            </p>
          </>
        )}

        <Input
          onClick={() => {
            global.$rbl.push(
              isPopWinIntent
                ? "car-detail_详情页线索引导弹层-预约看车_输入"
                : "car-detail_mobile"
            )
          }}
          size="large"
          className="phone"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value)
          }}
          placeholder="请输入您的手机号"
          maxLength={11}
        />
        {/* 防刷图形验证码 */}
        <ImgVerify
          className="image-verify"
          imgVerify={imgVerify}
          onImgVerifyCode={(code) => {
            setImgVerifyCode(code)
          }}
          onRefresh={() => {
            orderSubmit(true)
          }}
        />
        {/* 防刷短信验证码 */}
        <SmsCodeVerify
          autoSend
          phone={phone}
          smsVerify={smsVerify}
          onErrMsg={(err) => {
            setErrMsg(err)
          }}
          onSmsCode={(code) => {
            setSmsVerifyCode(code)
          }}
        />
        <p className="err-msg">{errMsg}</p>
        <Button
          data-click={`${gaPageName()}_submit`}
          size="large"
          type="primary"
          block
          disabled={!noteChecked}
          className={`submit ${isPopWinIntent ? "pop-win" : ""}`}
          onClick={orderSubmit}
        >
          {isPopWinIntent ? "预约看车" : "提交"}
        </Button>
        {!isPopWinIntent && (
          <div className="tip clearfix">
            <Checkbox
              className="check"
              checked={noteChecked}
              onChange={(e) => {
                setNoteChecked(e.target.checked)
              }}
            />
            <p className="txt">
              阅读并同意
              <a
                href="https://sta.renrenche.com/legal/terms.html"
                target="_blank"
                onClick={() => {
                  closeModal()
                }}
              >
                《用户服务协议》
              </a>
              、
              <a
                href="https://sta.renrenche.com/legal/privacy.html"
                target="_blank"
                onClick={() => {
                  closeModal()
                }}
              >
                《隐私政策》
              </a>
              提交并注册为用户。
            </p>
          </div>
        )}
      </div>
    </Modal>
  )
}

IntentOrder.propTypes = {
  intentType: PropTypes.string,
  visible: PropTypes.bool,
  isPopWin: PropTypes.bool,
  onClose: PropTypes.func,
  otherParams: PropTypes.object,
  submitParams: PropTypes.object
}

IntentOrder.defaultProps = {
  intentType: "order", // order: 预约；consult: 咨询
  visible: false,
  onClose: null,
  submitParams: null,
  otherParams: null,
  isPopWin: false
}

function mountAnywhere(Comp, root) {
  const div = document.createElement("div");
  (root || document.body).appendChild(div)

  // 向组件注入 onClose 方法，以便组件能调用关闭
  const Clone = React.cloneElement(Comp, {
    onClose: () => {
      ReactDOM.unmountComponentAtNode(div)
      div.parentNode.removeChild(div)
    }
  })
  ReactDOM.render(Clone, div)

  return div
}

export class UseIntentOrder {
  constructor(params) {
    this.visible = false
    this.ref = null
    this.submitParams = params.submitParams
    this.otherParams = params.otherParams
    this.intentType = params.intentType
    this.isPopWin = params.isPopWin
  }

  open() {
    this.visible = true
    this.ref = mountAnywhere(
      <IntentOrder
        visible={this.visible}
        isPopWin={this.isPopWin}
        intentType={this.intentType}
        submitParams={this.submitParams}
        otherParams={this.otherParams}
      />
    )

    return this.ref
  }

  close() {
    this.visible = false
  }
}
export default IntentOrder
