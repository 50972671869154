import Qs from 'qs'
import fetch from './axios.config'

let API_HOST = process.env.NODE_ENV === 'development' ? '/fe' : ''
const DEFAULT_CONFIG = {
  isAutoMsg: true,
  isLoading: true, // 自动loading
  isApiHost: true, // isApiHost  是否添加前缀 默认是true
  isSSRAPI: process.env.PRODUCT_ENV === 'testing' ? 'http://www.renrenche.com' : 'https://www.renrenche.com', // 在服务端请求接口默认主站地址
  isRemoveField: false, // 如果参数是空值是不传
  removeField: [] // 和 isRemoveField 一起使用
}

const POST_HEADER = {
  headers: {
    'content-type': 'application/json'
  }
}

/**
 *
 * url 处理如果 isApiHost 为true 则添加 API_HOST
 * @param {any} url
 * @param {any} isApiHost
 * @returns
 *
 */
function getUrl(url, { isApiHost, isSSRAPI }) {
  if (!isApiHost) {
    return url
  } if (!process.browser) {
    // TODO: isSSR 手动设置 改为 自动
    // 是否在服务端请求接口
    // 服务端 或 客户端渲染 根据 process.browser 判断
    API_HOST = isSSRAPI
  }

  const arr = url.indexOf('apiMock/') !== -1 ? [] : [API_HOST]
  if (!url.startsWith('/')) {
    arr.push('/')
  }
  arr.push(url)
  return arr.join('')
}

/**
 *
 * 移除提交请求中 列为空 null undefined 的值
 * @param {any} [params={}] 传入的参数
 * @param {any} [removeField=[]] 需要移除的列
 */
function removeEmptyField(params = {}, removeField = []) {
  const copyParams = JSON.parse(JSON.stringify(params))
  let arrField = removeField
  if (removeField.length === 0) {
    arrField = Object.keys(params)
  }
  arrField.forEach((key) => {
    const val = copyParams[key]
    if (val === '' || val === undefined || val === null) {
      delete copyParams[key]
    }
  })
  return copyParams
}

/**
 *
 * 处理参数 移除值是 空的 和加上一些用户信息等操作
 * @param {any} params 传入参数
 * @param {any} config 配置
 * @returns 返回新的参数
 */
function getParams(params, config) {
  // 用户相关
  if (!config.isRemoveField) {
    return params
  }
  return removeEmptyField(params, config.removeField)
}

/**
 * get 提交
 * @param {String} url 请求的url
 * @param {any} params  请求的参数
 * @param {Boolean} isApiHost  是否添加前缀 默认是true
 * @param {Obejct} config  请求配置
 * @returns Promise
 */
export function get(url, params = {}, config = {}) {
  const opts = { ...DEFAULT_CONFIG, ...config }
  opts.params = getParams(params, opts)
  return fetch.get(getUrl(url, opts), opts)
}

/**
 *
 * post 提交
 * @param {String} url 请求的url
 * @param {any} [params={}] 请求的参数
 * @param {Boolean} isApiHost 是否添加前缀 默认是true
 * @param {any} isApiHost 请求配置
 * @returns Promise
 *
 * @memberOf HttpBase
 */
export function post(url, params = {}, config = {}) {
  // 判断content-type
  const contentType = config.isFormData
    ? {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
    : POST_HEADER
  const opts = { ...DEFAULT_CONFIG, ...contentType, ...config }
  const res = config.isFormData
    ? Qs.stringify(getParams(params, opts))
    : getParams(params, opts)
  return fetch.post(getUrl(url, opts), res, opts)
}

/**
 *
 * put 提交
 * @param {String} url 请求的url
 * @param {any} [params={}] 请求的参数
 * @param {Boolean} isApiHost 是否添加前缀 默认是true
 * @param {any} isApiHost 请求配置
 * @returns Promise
 *
 * @memberOf HttpBase
 */
export function put(url, params = {}, config = {}) {
  const opts = { ...DEFAULT_CONFIG, ...POST_HEADER, ...config }
  return fetch.put(getUrl(url, opts.isApiHost), getParams(params, opts), opts)
}


export const removeField = removeEmptyField
