import React from 'react'

const DefaultOnSSR = () => (<span />)

class NoSSR extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = {
      canRender: false
    }
  }

  componentDidMount() {
    this.setState({ canRender: true })
  }

  // import NoSSR from 'react-no-ssr'
  // 确保在canRender=true设置成功，页面内容加载完成后重新收集埋点
  componentDidUpdate(prevProps, prevState) {
    !prevState.canRender && global.$rbl.collect()//eslint-disable-line
  }

  render() {
    const { children, onSSR = <DefaultOnSSR /> } = this.props // eslint-disable-line
    const { canRender } = this.state
    return canRender ? children : onSSR
  }
}

export default NoSSR
