import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import classnames from 'classnames'
import styles from './index.scss'
import { isCode } from '$utils'

const ImgVerify = (props) => {
  const [imgVerifyCode, setImgVerifyCode] = useState()

  // 图形验证码输入后校验
  useEffect(() => {
    if (props.imgVerify && imgVerifyCode && isCode(imgVerifyCode)) {
      props.onImgVerifyCode && props.onImgVerifyCode(imgVerifyCode)
    }
  }, [imgVerifyCode])

  useEffect(() => {
    setImgVerifyCode()
  }, [props.imgVerify])

  return (
    <>
      {props.imgVerify && (
      <div className={classnames(`${styles['r-img-verify']} clearfix`, props.wrapperClassName)}>
        <Input
          className="brush-code"
          size="large"
          placeholder="请输入验证码"
          bordered="false"
          maxLength={4}
          value={imgVerifyCode}
          onInput={(e) => {
            setImgVerifyCode(e.target.value)
          }}
        />
        <div className="brush-wrapper">
          <img className="brush-img" src={props.imgVerify} alt="" />
          <span className="brush-refresh" onClick={props.onRefresh}>
            <i className="iconfont icon-shuaxin" />
          </span>
        </div>
      </div>
      )}
    </>
  )
}

export default ImgVerify

ImgVerify.propTypes = {
  imgVerify: PropTypes.string,
  onImgVerifyCode: PropTypes.func,
  onRefresh: PropTypes.func,
  wrapperClassName: PropTypes.string
}

ImgVerify.defaultProps = {
  imgVerify: '',
  onImgVerifyCode: () => ({}),
  onRefresh: () => ({}),
  wrapperClassName: ''
}
