import React from 'react'
import propTypes from 'prop-types'
import Link from 'next/link'
import List from './list'
import styles from './index.scss'

const Menu = ({ route }) => (
  <div className={styles['r-menu']} id="menu">
    <ul className="r-menu__sidebar-navbar">
      {
        List.map(item => (
          <li className={route === item.route ? 'active' : ''} key={item.route}>
            <Link href={`/other/legal/${item.route}`}>
              <a>{item.name}</a>
            </Link>
          </li>
        ))
      }
    </ul>
  </div>
)
Menu.propTypes = {
  route: propTypes.string
}

Menu.defaultProps = {
  route: ''
}

export default Menu
