import {
 Button, Col, Input, Row, Select, message 
} from "antd"
import React, { useState, memo, useEffect } from "react"
import BrandSeriesSelect from "@/components/rc-brand-series-select"
import SmsCodeVerify from "@/components/rc-sms-verify"
import ImgVerify from "@/components/rc-img-verify"
import { useSelector } from "react-redux"
import LazyLoad from "react-lazyload"
import styles from "./index.scss"
import { bangmaiBuyer } from "../../service"
import { carAgeOptions, timeLimitOptions } from "../const"
import { UseModalSuccess } from "../../detail/[id]/modules/intent-modal/success"
import {
 isNumberInput, isPhone, $cookie, sessionstorage 
} from "$utils"

const Bangmai = memo(() => {
  const city = useSelector(store => store.city)
  const search = useSelector(store => store.search)
  const [age, setAge] = useState()
  const [series, setSeries] = useState(search?.series_name)
  const [brand, setBrand] = useState(search?.brand_name)
  const [price, setPrice] = useState()
  const [remark, setRemark] = useState()
  const [timeLimit, setTimeLimit] = useState()
  const [mobileNumber, setMobileNumber] = useState(
    $cookie.get("rrc_login_phone") || sessionstorage.get("intentPhone") || ""
  )

  const [errMsg, setErrMsg] = useState("")

  // 图形验证码
  const [imgVerify, setImgVerify] = useState()
  const [imgVerifyCode, setImgVerifyCode] = useState()

  // 短信验证码
  const [smsVerify, setSmsVerify] = useState()
  const [smsVerifyCode, setSmsVerifyCode] = useState()

  const onSubmit = async (_isrefresh) => {
    const isrefresh = _isrefresh && _isrefresh === true
    const source = "帮买-PC-主站列表页-3"
    global.$rbl.push(source)
    global.$rbl.push({
      event: "search_button-buycar",
      content: {
        param: source
      }
    })
    const params = {
      age,
      series,
      brand,
      price,
      remark,
      time_limit: timeLimit,
      mobile_number: mobileNumber,
      sell_source: "BUY",
      device: "pc",
      submit_sources: source,
      city: city.city_name,
      city_abbr: city.city
    }
    if (!isrefresh && imgVerifyCode) {
      params["code"] = imgVerifyCode
    }
    if (smsVerifyCode) {
      params["vcode"] = smsVerifyCode
    }
    if (!isPhone(params.mobile_number)) {
      setErrMsg("请输入手机号")
      return
    }
    if (!params.price) {
      setErrMsg("请输入意向价格")
      return
    }
    setErrMsg("")
    try {
      await bangmaiBuyer(params)
      global.$rbl.push("帮买-PC-主站列表页-3-提交成功")
      global.$rbl.push("pc_bangmai_submit_search_wujieguo")
      setImgVerify()
      setImgVerifyCode()
      new UseModalSuccess({
        msg: "帮买顾问已开始为您找车，请保持手机畅通",
        closeGa: "search_submitsuccesss_close"
      }).open()
    } catch (error) {
      if (error.status === 200) {
        const result = error.data
        const imgsrc = result?.image || result?.data?.image
        if (result.status === 100) {
          setImgVerify("")
          setErrMsg("")
        } else if (result.status === 400) {
          setImgVerify(imgsrc)
          setImgVerifyCode()
        } else if (result.status === 1000) {
          // 短信验证码
          setSmsVerify(true)
        } else if (result.status === 1001) {
          // 短信验证码错误
          setErrMsg("短信验证码输入有误")
        } else {
          message.error(result?.err_msg || result?.errMsg || "未知错误")
        }
      }
    }
  }

  useEffect(() => {
    if (search?.searchObjLabel) {
      setRemark(search.searchObjLabel)
    }
  }, [search?.searchObjLabel])

  useEffect(() => {
    if (search?.brand_name) {
      setBrand(search?.brand_name)
    }
  }, [search?.brand_name])

  useEffect(() => {
    if (search?.series_name) {
      setSeries(search?.series_name)
    }
  }, [search?.series_name])
  useEffect(() => {
    if (search?.searchPrice) {
      setPrice(search?.searchPrice)
    }
  }, [search?.searchPrice])

  useEffect(() => {
    if (search?.searchAge) {
      setAge(search?.searchAge)
    }
  }, [search?.searchAge])

  return (
    <LazyLoad>
      <div className={styles["r-bangmai"]}>
        <div className="r-bangmai_wrapper">
          <div className="bangmai-txt">
            <h1 className="title">帮我找车</h1>
            <h2 className="desc">
              把想要的车辆告诉我们！ 当有符合要求的车辆上架， 第一时间通知您！
            </h2>
          </div>
          <div className="bangmai-form">
            <p className="title">
              <i className="iconfont icon-haoche"></i>求购好车
              <span className="hot-line">咨询电话：400-0266-913</span>
            </p>
            <Row gutter={10}>
              <Col span={12}>
                <BrandSeriesSelect
                  wrapperClass="form-item"
                  placeholder="请选择欲购车辆"
                  onSelected={(d) => {
                    d.brand_name && setBrand(d.brand_name)
                    d.series_name && setSeries(d.series_name)
                  }}
                ></BrandSeriesSelect>
              </Col>
              <Col span={12}>
                <Select
                  className="form-item"
                  value={age}
                  onChange={(v) => {
                    setAge(v)
                  }}
                  placeholder="车龄要求"
                  size="large"
                >
                  {carAgeOptions.map(o => (
                    <Select.Option key={o.key} value={o.key}>
                      {o.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={12}>
                <Select
                  className="form-item"
                  value={timeLimit}
                  onChange={(v) => {
                    setTimeLimit(v)
                  }}
                  placeholder="您计划的提车时间是？"
                  size="large"
                >
                  {timeLimitOptions.map(o => (
                    <Select.Option key={o} value={o}>
                      {o}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={12}>
                <Input
                  className="form-item"
                  placeholder="输入预算"
                  suffix="万元内"
                  size="large"
                  value={price}
                  onInput={(e) => {
                    if (isNumberInput(e.target.value)) {
                      setPrice(e.target.value)
                    }
                  }}
                />
              </Col>
              <Col span={24}>
                <Input.TextArea
                  rows={3}
                  placeholder="老板描述下心中爱车？如：3万公里以内，颜色红色，真皮内饰等..."
                  value={remark}
                  onInput={(e) => {
                    setRemark(e.target.value)
                  }}
                />
              </Col>

              {imgVerify && (
                <Col span={24}>
                  {/* 防刷图形验证码 */}
                  <ImgVerify
                    wrapperClassName="row"
                    imgVerify={imgVerify}
                    imgVerifyCode={imgVerifyCode}
                    onImgVerifyCode={(code) => {
                      setImgVerifyCode(code)
                    }}
                    onRefresh={() => {
                      onSubmit(true)
                    }}
                  />
                </Col>
              )}
              {smsVerifyCode && (
                <Col span={24}>
                  {/* 防刷短信验证码 */}
                  <SmsCodeVerify
                    autoSend
                    wrapperClassName="row"
                    phone={mobileNumber}
                    smsVerify={smsVerify}
                    onErrMsg={(err) => {
                      setErrMsg(err)
                    }}
                    onSmsCode={(code) => {
                      setSmsVerifyCode(code)
                    }}
                  />
                </Col>
              )}

              <Col span={24}>
                <div className="submit">
                  <Input
                    className="phone"
                    placeholder="11位手机号"
                    size="large"
                    value={mobileNumber}
                    maxLength={11}
                    onFocus={() => {
                      global.$rbl.push("search_inputbox-mobilenumber")
                    }}
                    onInput={(e) => {
                      if (isNumberInput(e.target.value)) {
                        setMobileNumber(e.target.value)
                      }
                    }}
                  />
                  <Button className="btn" onClick={onSubmit}>
                    <img
                      src="//img1.rrcimg.com/dist/pc/images/bell-20-20-c68a159d.gif"
                      alt="bell"
                    />
                    有符合车辆通知我
                  </Button>
                </div>
              </Col>
              {errMsg && (
                <Col span={24}>
                  <span className="err">{errMsg}</span>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>
    </LazyLoad>
  )
})
export default Bangmai

Bangmai.propTypes = {}

Bangmai.defaultProps = {}
